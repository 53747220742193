import React from "react"
import { NUMBERS } from "./phone_numbers"
import { pageevent } from "./ga"

export default function PhoneNumber() {
  return (<>
    {
      Object.keys(NUMBERS).map(k => {
        return <a key={k} className={`phone-number ${k}`} href={NUMBERS[k].link} onClick={() => pageevent("click", "phone-number")}>{NUMBERS[k].display}</a>
      })
    }
  </>)
}