import React from "react"
import PhoneNumber from "./PhoneNumber"
import Help from "./Help"
import { NUMBERS } from "./phone_numbers"
import "./Helppage.css"
import { pageevent } from "./ga"
import HomepageFooter from "./HomepageFooter"

interface HelppageProps {
  country: string
}

export default function Helppage(props: HelppageProps) {
  document.body.className = `country-${props.country}`
  let phoneNumber = NUMBERS[props.country]
  if (typeof phoneNumber === "undefined") {
    phoneNumber = NUMBERS["US"]
  }
  return (<div className="homepage help">
    <header className="header">
      <a href="/">
        <img src="./images/hp2_logo.svg" alt="Tiny Log" />
      </a>
      <div className="spacer"></div>
      <PhoneNumber />
    </header>
    <div className="brick"></div>
    <Help className="tips" phoneNumber={phoneNumber} />
    <div className="content">
      <section className="section1">
        <div className="balloon large small">
          <p>Ready to try it?</p>
          <div className="cat-snooze"></div>
        </div>
        <div className="box right">
          <div className="balloon button">
            <button onClick={() => {
              pageevent("click", "help-start")
              window.location.href = `${phoneNumber.link};?&body=Hello`}
            }>Text "Hello" to start logging <span role="img" aria-label="arrow">➡️</span></button>
          </div>
        </div>
        <div className="balloon large small logging-CA">
          <p>Logging from Canada?<br/><a href={NUMBERS["CA"].link}>Text {NUMBERS["CA"].display}</a></p>
        </div>
        <div className="balloon large small logging-US">
          <p>Logging from the US?<br/><a href={NUMBERS["US"].link}>Text {NUMBERS["US"].display}</a></p>
        </div>
      </section>
    </div>
    <HomepageFooter />
  </div>)
}