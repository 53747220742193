import * as firebase from "firebase"
import { Message } from "./textline_view"

const firebaseConfig = {
  apiKey: "AIzaSyBZ3inAeRPbmfinbT3oMphK2IcZNhnizIg",
  authDomain: "tinylog-alpha-27ecb.firebaseapp.com",
  databaseURL: "https://tinylog-alpha-27ecb.firebaseio.com",
  projectId: "tinylog-alpha-27ecb",
  storageBucket: "tinylog-alpha-27ecb.appspot.com",
  messagingSenderId: "151744639134",
  appId: "1:151744639134:web:4c604efc61e8f040e34b8f",
  measurementId: "G-5HWEBQJVBC"
}
firebase.initializeApp(firebaseConfig)

export class TextlineDatabase {
  private coll: firebase.firestore.CollectionReference
  onNewMessage: (message: Message) => void
  onMessageRemoved: (id: string) => void

  constructor(onNewMessage: (message: Message) => void, onMessageRemoved: (id: string) => void) {
    this.coll = firebase.firestore().collection("ether")
    this.onNewMessage = onNewMessage
    this.onMessageRemoved = onMessageRemoved
    this.coll.onSnapshot((doc) => this._onNewMessage(doc), (e) => console.error(e))
  }

  _onNewMessage(ss: firebase.firestore.QuerySnapshot) {
    if (typeof this.onNewMessage === "undefined") return
    if (this.onNewMessage === null) return
    ss.docChanges().forEach(docChange => {
      if (docChange.type === "added") {
        this.onNewMessage({
          id: docChange.doc.id,
          body: docChange.doc.get("body"),
          created: docChange.doc.get("created")
        })
      } else if (docChange.type === "removed") {
        this.onMessageRemoved(docChange.doc.id)
      }
    })
  }
}