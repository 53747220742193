import React from "react"
import "./Homepage.css"
import PhoneNumber from "./PhoneNumber"
import { NUMBERS } from "./phone_numbers"
import { pageevent } from "./ga"
import HomepageFooter from "./HomepageFooter"

interface HomepageState {
  carouselIndex: number
}

const carouselCaptions = [
  "You send a text",
  "Tiny Log keeps track",
  "Text \"get log\" for your link"
]

export default class Homepage extends React.PureComponent<{}, HomepageState> {
  cleaners: (() => void)[] = []

  constructor(props: {}) {
    super(props)
    this.state = { carouselIndex: 0 }
  }

  country() {
    const c = document.body.className
    const a = c.split(" ")
    let country: string | null = null
    for (const n of a) {
      if (n.indexOf("country-") < 0) continue
      country = n.replace("country-", "")
    }
    if (country === null) return "US"
    return country
  }

  onCarouselScroll(e: React.UIEvent<HTMLDivElement>) {
    const div = e.target as HTMLDivElement
    const carouselIndex = Math.floor(0.3 + div.scrollLeft / (div.scrollWidth / 3 - 32))
    this.setState({carouselIndex: carouselIndex})
  }

  scrollCarouselTo(index: number) {
    const div = document.querySelector(".carousel")!
    div.scrollTo({
      top: 0,
      left: index * (div.scrollWidth / 3 - 32),
      behavior: "smooth"
    })
  }

  addGeoIPScript(): () => void {
    const script1 = document.createElement("script")
    const text = document.createTextNode(`
      function test(data) {
        const c = data.country_code
        document.body.className = document.body.className + " country-" + c
      }
    `)
    script1.appendChild(text)
    const script2 = document.createElement("script")
    script2.src = "https://freegeoip.app/json/?callback=test"
    script2.async = true
    document.body.appendChild(script1)
    document.body.appendChild(script2)
    return () => {
      document.body.removeChild(script2)
      document.body.removeChild(script1)
    }
  }

  addScrollWatcher(): () => void {
    const listener = () => {
      const target = document.scrollingElement
      if (target === null) return
      document.querySelectorAll("header > a.phone-number").forEach(e => {
        e.className = target.scrollTop > 88
          ? e.className.indexOf("highlight") >= 0 ? e.className : e.className + " highlight"
          : e.className.replace(" highlight", "")
      })
    }
    window.addEventListener("scroll", listener)
    return () => {
      window.removeEventListener("scroll", listener)
    }
  }

  componentDidMount() {
    this.cleaners.push(this.addGeoIPScript())
    this.cleaners.push(this.addScrollWatcher())
  }

  componentWillUnmount() {
    this.cleaners.forEach(fn => fn())
  }

  onText() {
    pageevent("click", "homepage-start")
    const phoneNumber = NUMBERS[this.country()]
    window.location.href = phoneNumber.link + ";?&body=Hello"
  }

  onHelp() {
    pageevent("click", "homepage-help")
    const country = this.country()
    window.location.href = `/help#${country}`
  }

  onTellAFriend() {
    pageevent("click", "homepage-share")
    const shareData = {
      title: "Tiny Log",
      text: "You just text to track your baby's sleep. Try it here:",
      url: "https://tinylog.iandco.com",
    }
    try {
      // @ts-ignore navigator.share is there in many browsers esp. in mobile.
      navigator.share(shareData)
    } catch(err) {
      // but when it failed, fallback to email
      window.location.href = "mailto:?subject=Tiny%20Log%20Baby%20Sleep%20Journal&body=You%20just%20text%20to%20track%20your%20baby's%20sleep.%20Try%20it%20here%3A%20https%3A%2F%2Ftinylog.iandco.com"
    }
  }

  render() {
    return (<div className="homepage">
      <header className="header">
        <img src="./images/hp2_logo.svg" alt="Tiny Log" />
        <div className="spacer"></div>
        <PhoneNumber />
      </header>
      <div className="brick"></div>
      <div className="content">
        <section className="section1">
          <div className="balloon large">
            <p>Track your baby's sleep with a text <span role="img" aria-label="text">💬</span></p>
            <p>No app, download or login required</p>
            <div className="cat-snooze"></div>
          </div>
          <div className="box right">
            <div className="balloon button">
              <button onClick={() => this.onText()}>Text "Hello" to start logging <span role="img" aria-label="arrow">➡️</span></button>
            </div>
          </div>
        </section>
        <div className="carousel" onScroll={(e) => this.onCarouselScroll(e)}>
          <div className="container">
            <img src="/images/you-send-a-text.png" alt="you send a text" />
            <img src="/images/tiny-log-keeps-track.png" alt="tiny log keeps track" />
            <img src="/images/compare-days-with-timeline-view.png" alt="compare days with timeline view" />
          </div>
        </div>
        <div className="control">
          <span className="caption">{carouselCaptions[this.state.carouselIndex]}</span>
          <ul className="indicator">
            <li className={`dot${this.state.carouselIndex === 0 ? " white" : ""}`} onClick={(e) => this.scrollCarouselTo(0)}></li>
            <li className={`dot${this.state.carouselIndex === 1 ? " white" : ""}`} onClick={(e) => this.scrollCarouselTo(1)}></li>
            <li className={`dot${this.state.carouselIndex === 2 ? " white" : ""}`} onClick={(e) => this.scrollCarouselTo(2)}></li>
          </ul>
        </div>
        <div className="captions">
          <ul className="captions">
            {
              carouselCaptions.map((c, i) => <li key={i} className="caption">{c}</li>)
            }
          </ul>
        </div>
        <div className={`help-link${this.state.carouselIndex === 2 ? " on" : ""}`}>
          <button onClick={() => this.onHelp()}>See what else you can do</button>
        </div>
        <section className="section3">
          <div className="balloon large xlarge">
            <p>Born out of the need for an easier way to track sleep, Tiny Log is text-based and syncs across multiple devices without compromising your privacy. Created by parents, for parents. </p>
            <div className="cat-peek"></div>
          </div>
          <div className="box right">
            <div className="balloon button button-teal">
              <button onClick={() => this.onTellAFriend()}>Tell a friend about Tiny Log <span role="img" aria-label="point">👉</span></button>
            </div>
          </div>
        </section>
      </div>
      <HomepageFooter />
    </div>)
  }
}