import React from "react"
import { pageview } from "./ga"

interface AnalyticsProps {
  page: string
}

// This component doesn't render anything. It just sends pageview event.
export default function Analytics(props: AnalyticsProps) {
  pageview(props.page)
  return (<></>)
}