import { IEntry } from "./Entry"
import { Filters } from "./Filter"
import { User } from "./user"
import spacetime, { Spacetime } from "spacetime"

export interface IDatabase {
  loadTrackings: (filter: Filters, startAfter: number | null) => Promise<IEntry[]>
  updateTracking: (id: string, data: Partial<IEntry>) => Promise<IEntry>
  deleteTracking: (entryId: string) => Promise<void>
  loadCalendar: (dateUntil: Spacetime | null) => Promise<{ since: number, until: number, entries: IEntry[] }[]>
}

const ENDPOINT_URL = "https://us-central1-tinylog-alpha-27ecb.cloudfunctions.net/api/tracking"
// const ENDPOINT_URL = "http://localhost:5000/tinylog-alpha-27ecb/us-central1/api/tracking"

const THRESHOLD_DATE = spacetime([2019, 9, 1, 0, 0, 0], "America/New_York")

export class ApiDatabase implements IDatabase {
  private user: User
  constructor(user: User) {
    this.user = user
  }

  async loadTrackings(filter: Filters, startAfter: number | null) {
    const endpoint = `${ENDPOINT_URL}/${this.user.id}`
    const queries = Object.keys(filter)
      // @ts-ignore yes we can index filter by string name
      .filter(k => (typeof filter[k]) !== "undefined" && (typeof filter[k]) !== "object" && filter[k] !== null)
      // @ts-ignore yes we can index filter by string name
      .map(k => `${k}=${encodeURIComponent(filter[k])}`)
    if (filter.dateFrom !== null) {
      queries.push(`dateFrom=${filter.dateFrom.year()}-${filter.dateFrom.month() + 1}-${filter.dateFrom.date()}`)
      if (filter.dateTo === null) {
        queries.push(`dateTo=${filter.dateFrom.year()}-${filter.dateFrom.month() + 1}-${filter.dateFrom.date()}`)
      } else {
        queries.push(`dateTo=${filter.dateTo.year()}-${filter.dateTo.month() + 1}-${filter.dateTo.date()}`)
      }
    }
    if (startAfter !== null) {
      queries.push(`startAfter=${startAfter}`)
    }
    const query = queries.join("&")
    return fetch(`${endpoint}?${query}`).then(res => {
      if (res.status >= 400) {
        return res.text().then(text => {
          console.error(text)
          throw new Error(text)
        })
      } else {
        return res.json().then(json => {
          const data = json as any[]
          return data.map(d => ({
            id: d.id,
            timestamp: d.timestamp,
            duration: d.duration,
            category: d.category,
            rawBody: d.rawBody,
            timezone: d.timezone
          }))
        })
      }
    })
  }

  async updateTracking(id: string, data: Partial<IEntry>) {
    const entryId = id
    if (typeof entryId === "undefined" || entryId === null) throw new Error("Missing entry id")
    const endpoint = `${ENDPOINT_URL}/${this.user.id}/${entryId}`
    return fetch(endpoint, {
      method: "put",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    }).then(res => {
      if (res.status >= 400) {
        return res.text().then(text => {
          console.error(text)
          throw new Error(text)
        })
      } else {
        return res.json().then(json => {
          return {
            id: json.id,
            timestamp: json.timestamp,
            duration: json.duration,
            category: json.category,
            rawBody: json.rawBody,
            timezone: json.timezone
          }
        })
      }
    })
  }

  async deleteTracking(entryId: string) {
    const endpoint = `${ENDPOINT_URL}/${this.user.id}/${entryId}`
    return fetch(endpoint, {
      method: "delete",
      headers: {
        "Content-Type": "application/json"
      },
    }).then(res => {
      if (res.status >= 400) {
        return res.text().then(text => {
          console.error(text)
          throw new Error(text)
        })
      } else {
        return
      }
    })
  }

  loadCalendar(dateUntil: Spacetime | null): Promise<{ since: number, until: number, entries: IEntry[] }[]> {
    if (dateUntil !== null && dateUntil.epoch < THRESHOLD_DATE.epoch) {
      return Promise.resolve([])
    }
    const endpoint = `${ENDPOINT_URL}/${this.user.id}/calendar`
    const query = dateUntil === null
      ? ""
      : `?dateUntil=${dateUntil.year()}-${dateUntil.month() + 1}-${dateUntil.date()}`
    return fetch(`${endpoint}${query}`).then(res => {
      if (res.status >= 400) {
        return res.text().then(text => {
          console.error(text)
          throw new Error(text)
        })
      } else {
        return res.json()
      }
    })
  }
}
