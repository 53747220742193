import React from "react"
import Lottie from "lottie-web"

export default class Loading extends React.PureComponent {
  onGotElement(element: HTMLDivElement | null) {
    if (element === null) return
    const data = require("./cat-snooze.json")
    Lottie.loadAnimation({
      container: element,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: data
    });
  }
  render() {
    return (
      <div className="loading-animation" ref={(e) => this.onGotElement(e)}></div>
    )
  }  
}