import React from "react"
import "./Textline.css"
import { TextlineDatabase } from "./textline_firestore"
import { TextlineView } from "./textline_view"
import Loading from "./Loading"
import { pageevent } from "./ga"

interface TextlineProps {
  userId: string
  width: number
  height: number
  onBack: (userId: string) => void
}

interface TextlineState {
  showHelp: boolean
  loading: boolean
}

const ItemWidths = [
  192,
  256,
  224,
  224,
  192
]

export default class Textline extends React.PureComponent<TextlineProps, TextlineState> {
  private database: TextlineDatabase | null
  private view: TextlineView | null

  constructor(props: TextlineProps) {
    super(props)
    this.view = null
    this.database = null
    this.state = {
      showHelp: false,
      loading: true
    }
  }

  componentDidMount() {
    window.scrollTo(window.innerWidth / 2, 0)
  }

  onHoldMessage(id: string) {
    pageevent("textline", "hold-message", id)
  }

  onGotView(ul: HTMLUListElement | null) {
    if (ul === null) return
    if (this.view !== null) return
    this.view = new TextlineView(
      window.document,
      ul,
      this.props.width,
      this.props.height,
      ItemWidths,
      this.onHoldMessage
    )
    this.database = new TextlineDatabase(
      (message) => {
        this.setState({ loading: false })
        this.view!.onNewMessage(message)
      },
      (message) => this.view!.onMessageRemoved(message)
    )
    this.view.animate()
  }

  // sendNow() {
  //   window.location.href = "sms:+18312336236"
  // }

  render() {
    return (
      <div className="Textline">
        <ul className="notes" ref={(e) => this.onGotView(e)}>
        </ul>
        <button className="close-button" onClick={() => {
          pageevent("textline", "close")
          this.props.onBack(this.props.userId)
        }
        }>
          <img alt="Close" src="/cross.svg" />
        </button>
        <button className="help-button" onClick={() => {
          pageevent("textline", "help-open")
          this.setState({ showHelp: true })
        }
        }>
          <img src="/images/help-button.svg" alt="help" />
        </button>
        {/* <button className="send" onClick={() => {
          pageevent("textline", "send")
          this.sendNow()
        }
        }>
          <img src="/images/send-button.svg" alt="send" />
        </button> */}
        <div className={`help${this.state.showHelp ? " on" : ""}`}>
          <button className="close-button" onClick={() => {
            pageevent("textline", "help-close")
            this.setState({ showHelp: false })
          }
          }></button>
          <p>You've reached the Tiny Textline.</p>
          <p>Vent. Encourage. Celebrate.</p>
          <p>All messages are anonymous.</p>
          {/* <p className="send-now" onClick={() => {
            pageevent("textline", "click-number-in-help")
            this.sendNow()}
          }>831.233.6236</p> */}
        </div>
        {this.state.loading ? <Loading /> : null}
      </div>
    )
  }
}