export function setUserId(userId: string) {
  // @ts-ignore
  const ga: any = window.ga
  if (!!ga) ga("set", "userId", userId)
}

export function pageview(page: string) {
  // @ts-ignore
  const ga: any = window.ga
  if (!!ga) {
    // "dimension1" corresponds to "tinylog-pwa" in GA UI.
    // @ts-ignore navigator.standalone is there for sure.
    if ("standalone" in window.navigator && window.navigator.standalone) {
      //Safari
      ga("set", "dimension1", "Safari")
    } else if (window.matchMedia("(display-mode: standalone)").matches) {
      //Chrome
      ga("set", "dimension1", "Chrome")
    } else {
      ga("set", "dimension1", "(no)")
    }
    ga("send", "pageview", page)
  }
}

export function pageevent(category: string, action: string, label?: string, value?: number) {
  // @ts-ignore
  const ga: any = window.ga
  if (!!ga) ga("send", "event", category, action, label, value)
}