import React from "react"
import * as TLDateTime from "./datetime"
import { Category } from "./category"

export interface IEntry {
  id: string
  timestamp: number
  duration: number
  category: Category
  rawBody: string
  timezone: string
}

interface EntryProps {
  entry: IEntry
  onClick?: () => void
}

const MAX_BAR_LENGTH = 12 * 60 * 60 * 1000 // 12 hours === 100%

const onClick = (props: EntryProps) => {
  if (typeof props.onClick === "undefined") return
  props.onClick()
}

export default function Entry(props: EntryProps) {
  const entry = props.entry
  let durationSpan = null
  let bar = null
  let dogear = null
  let bodyClass = ""
  if (entry.category === Category.UNKNOWN) {
    dogear = <><div className="dogear-behind"></div><div className="dogear-top"></div></>
    bodyClass = " note"
  } else {
    const duration = entry.duration === 0
      ? Date.now() - entry.timestamp
      : entry.duration
    durationSpan = <span className="duration">{`${TLDateTime.formatDuration(duration)}${(entry.duration === 0 ? " so far" : "")}`}</span>
    const barLength = (duration / MAX_BAR_LENGTH) * (entry.duration === 0 ? 98 : 100)
    bar = <div className="bar">
      <div className={`category-${entry.category}`} style={{ width: `${Math.min(barLength, 100)}%` }}></div>
      {
        entry.duration === 0 ? <img alt="Ongoing" src={`ongoing-${entry.category}.svg`} style={{ left: `${Math.min(barLength, 100)}%` }} /> : null
      }
    </div>
  }
  return (<li onClick={() => onClick(props)}>
    <div className="timestamp">
      <span className="datetime">{TLDateTime.formatDateTime(entry.timestamp, entry.timezone)}</span>
      {durationSpan}
      {dogear}
    </div>
    <div className={`body${bodyClass}`}>{entry.rawBody}</div>
    {bar}
  </li>)
}