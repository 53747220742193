import React from "react"
import { IDatabase } from "./database"

// NOTE This enum has to be kept in sync with functions/src/model/user.ts
export enum UserStatus {
  UNKNOWN,
  INITIALIZED,
  ONBOARDED,
  ACTIVE,
  INACTIVE,
  SHARED,
  NEEDS_ONBOARDING
}

export interface User {
  id: string
  trackingId: string
  timezone: string
  country: "US" | "CA"
  dayStarts: number
  status: UserStatus
  didUseCalendar: boolean
}

interface IUserContext {
  user: User
  database: IDatabase
  userDatabase: UserDatabase
  updateUser: (user: User) => void
}

export const UserContext = React.createContext<IUserContext>({
  user: {} as User,
  database: {} as IDatabase,
  userDatabase: {} as UserDatabase,
  updateUser: () => {}
})

export class UserDatabase {
  private backend: UserDatabaseBackend
  constructor(backend: UserDatabaseBackend) {
    this.backend = backend
  }
  loadUser(id: string): Promise<User> {
    return this.backend.loadUser(id)
  }
  updateUser(id: string, args: Partial<User>): Promise<User> {
    return this.backend.updateUser(id, args)
  }
}

interface UserDatabaseBackend {
  loadUser(id: string): Promise<User>
  updateUser(id: string, args: Partial<User>): Promise<User>
}

const ENDPOINT_URL = "https://us-central1-tinylog-alpha-27ecb.cloudfunctions.net/api/user"
// const ENDPOINT_URL = "http://localhost:5000/tinylog-alpha-27ecb/us-central1/api/user"
export class ApiUserDatabase implements UserDatabaseBackend {
  async loadUser(id: string) {
    const endpoint = `${ENDPOINT_URL}/${id}`
    return fetch(endpoint).then(res => {
      if (res.status >= 400) {
        return res.text().then(text => {
          console.error(text)
          throw new Error(text)
        })
      } else {
        return res.json().then(json => {
          return {
            id: json.id,
            trackingId: json.trackingId,
            timezone: json.timezone,
            country: json.country,
            dayStarts: (typeof json.dayStarts === "undefined") ? 0 : json.dayStarts,
            status: json.status,
            didUseCalendar: (typeof json.didUseCalendar === "undefined") ? false: json.didUseCalendar
          }
        })
      }
    })
  }

  async updateUser(id: string, args: Partial<User>): Promise<User> {
    const endpoint = `${ENDPOINT_URL}/${id}`
    return fetch(endpoint, {
      method: "put",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(args)
    }).then(res => {
      if (res.status >= 400) {
        return res.text().then(text => {
          console.error(text)
          throw new Error(text)
        })
      } else {
        return res.json().then(json => {
          return {
            id: json.id,
            trackingId: json.trackingId,
            timezone: json.timezone,
            country: json.country,
            dayStarts: (typeof json.dayStarts === "undefined") ? 0 : json.dayStarts,
            status: json.status,
            didUseCalendar: (typeof json.didUseCalendar === "undefined") ? false: json.didUseCalendar
          }
        })
      }
    })
  }
}
