import React from "react"
import { IEntry } from "./Entry"
import "./Entries.css"
import Analytics from "./Analytics"
import { pageevent } from "./ga"
import Loading from "./Loading"
import { UserContext } from "./user"
import spacetime from "spacetime"
import Day from "./Day"
import DayHeader from "./DayHeader"
import { parseTimeNumber } from "./datetime"
import ModalEntry from "./ModalEntry"

export interface CalendarEntry {
  since: number
  until: number
  entries: IEntry[]
}

interface CalendarProps {
  now: number
  onDateChanged: (date: string) => void
  goToTips: () => void
}

interface CalendarState {
  entries: CalendarEntry[]
  moreLoadedAt?: number
  selectedIndex?: number
  selectedEntryIndex?: number
  isLoading: boolean
}

// If 3 for example, "loadMore" kicks in when the scrolling reaches the third to the last element at the bottom.
const LOAD_MORE_THRESHOLD = 3

export default class Calendar extends React.PureComponent<CalendarProps, CalendarState> {
  static contextType = UserContext
  context!: React.ContextType<typeof UserContext>
  private currentIndex: number
  private currentScrollTop: number
  private currentDate: string

  constructor(props: CalendarProps) {
    super(props)
    this.currentIndex = 0
    this.currentScrollTop = 0
    this.currentDate = ""
    this.state = {
      entries: [],
      isLoading: true
    }
  }

  componentDidMount() {
    this._loadFirst()
  }

  onGotEntriesList(e: HTMLUListElement | null) {
    if (e === null) return
    // HACK: remove the bottom bar height from the entries list
    // so you can see the very bottom on top of the bottom bar in iOS Safari or Android Chrome.
    e.parentElement!.parentElement!.style.height = (window.innerHeight - e.parentElement!.parentElement!.offsetTop) + "px"
  }

  _loadFirst() {
    this.setState({ entries: [], isLoading: true })
    this.context.database.loadCalendar(null).then(entries => {
      if (entries.length === 0) {
        this.setState({ isLoading: false })
        return
      }
      const timezone = this.context.user.timezone
      const date = spacetime(entries[0].since, timezone)
      const month = spacetime([date.year(), date.month(), 1, 0, 0, 0, 0], timezone).format("{month} {year}") as string
      this.props.onDateChanged(month)

      this.setState({ entries: entries, isLoading: false })
    })
  }

  _loadMore() {
    this.setState({ isLoading: true })
    const lastEntry = this.state.entries[this.state.entries.length - 1]
    const until = spacetime(lastEntry.since, this.context.user.timezone)
    this.context.database.loadCalendar(until).then(entries => {
      if (entries.length === 0) {
        this.setState({ moreLoadedAt: lastEntry.since, isLoading: false })
        return
      }
      this.setState({
        entries: [...this.state.entries, ...entries],
        moreLoadedAt: lastEntry.since,
        isLoading: false
      })
    })
  }

  _scrollDayHeader(e: React.UIEvent<HTMLDivElement>) {
    const entries = e.target as HTMLDivElement
    if (entries === null) return e.preventDefault()
    // XXX: These won't happen in browsers except iOS Safari with "-webkit-overflow-scrolling".
    if (entries.scrollTop < 0) return e.preventDefault()
    const dayHeader = entries.querySelector(".day-header") as HTMLUListElement
    if (dayHeader === null) return e.preventDefault()
    dayHeader.style.top = `${-entries.scrollTop}px`
  }

  _scrollTimeHeader(e: React.UIEvent<HTMLDivElement>) {
    const entries = e.target as HTMLDivElement
    if (entries === null) return e.preventDefault()
    // XXX: These won't happen in browsers except iOS Safari with "-webkit-overflow-scrolling".
    if (entries.scrollTop < 0) return e.preventDefault()
    const timeHeader = window.document.querySelector(".time-header-container") as HTMLUListElement
    if (timeHeader === null) return e.preventDefault()
    timeHeader.scrollLeft = entries.scrollLeft
  }

  _scrollFooter(e: React.UIEvent<HTMLDivElement>) {
    const entries = e.target as HTMLDivElement
    if (entries === null) return e.preventDefault()
    // XXX: These won't happen in browsers except iOS Safari with "-webkit-overflow-scrolling".
    if (entries.scrollLeft < 0 || entries.scrollLeft > entries.offsetWidth) return e.preventDefault()
    const footer = entries.querySelector(".footer-content") as HTMLDivElement
    if (footer === null) return e.preventDefault()
    footer.style.left = `${entries.scrollLeft}px`
  }

  /**
   * Returns the index of the current first visible HTMLLiElement and the current scrollTop property of the container
   */
  _findTopEntry(div: HTMLDivElement): [number, number] | null {
    if (div.scrollTop === 0) return [0, 0]

    // TODO can we cache this?
    const listItems = div.querySelectorAll("li")

    // We usually want to start searching for the current first visible entry from the previous position...
    let i = this.currentIndex
    if (this.currentScrollTop > div.scrollTop) {
      // But if we are scrolling up, start searching for the element from entries a little above from the previous one
      i = Math.max(i - 10, 0)
    }
    for (; i < listItems.length; i++) {
      const li = listItems[i]
      if (div.scrollTop > li.offsetTop && div.scrollTop < li.offsetTop + li.offsetHeight) {
        return [i, div.scrollTop]
      }
    }
    return null
  }

  _onScroll(e: React.UIEvent<HTMLDivElement>) {
    this._scrollDayHeader(e)
    this._scrollTimeHeader(e)
    this._scrollFooter(e)
    if (this.state.isLoading) return
    const div = e.target as HTMLDivElement
    this._checkDateChange(div)
    const scrollingUp = this.currentScrollTop > div.scrollTop
    const canLoadMore = this.state.entries.length === 0
      || this.state.moreLoadedAt !== this.state.entries[this.state.entries.length - 1].since
    if (!scrollingUp && canLoadMore) {
      this._checkAtBottom(div)
    }
  }

  _checkDateChange(div: HTMLDivElement) {
    const found = this._findTopEntry(div)
    if (found === null) return
    if (found[0] === this.currentIndex) return
    if (found[0] >= this.state.entries.length) return
    const topEntry = this.state.entries[found[0]]

    const timezone = this.context.user.timezone
    const date = spacetime(topEntry.since, timezone)
    const month = spacetime([date.year(), date.month(), 1, 0, 0, 0, 0], timezone).format("{month} {year}") as string
    this.currentIndex = found[0]
    this.currentScrollTop = found[1]
    if (month !== this.currentDate) {
      this.currentDate = month
    }
    this.props.onDateChanged(month)
  }

  _checkAtBottom(div: HTMLDivElement) {
    if (div.scrollTop === 0) return
    const listItems = div.querySelectorAll("li")
    if (listItems === null || listItems.length < LOAD_MORE_THRESHOLD) return

    if (div.scrollTop + div.offsetHeight > listItems[listItems.length - LOAD_MORE_THRESHOLD - 1].offsetTop - div.offsetTop) {
      this._loadMore()
    }
  }

  _timeHeader(dayStarts: number) {
    const [hour, minute] = parseTimeNumber(dayStarts)
    const columns = []
    for (let i = 0; i < 12; i++) {
      let h = (hour + 2 * i) % 24
      const ampm = h > 11 ? "pm" : "am"
      h = h % 12
      if (h === 0) h = 12
      let m = `${minute}`
      if (minute === 0) m = " "
      else if (minute < 10) m = `0${minute}`
      columns.push(<th colSpan={2} key={`${h}${m}${ampm}`}><span>{`${h}${m}${ampm}`}</span></th>)
    }
    return columns
  }

  _scaleMarkers() {
    const columns = []
    for (let i = 0; i < 24; i++) {
      columns.push(<td key={i}></td>)
    }
    return columns
  }

  _nowPosition(dayStarts: number, timezone: string): string {
    const [hour, minute] = parseTimeNumber(dayStarts)
    const now = spacetime.now(timezone)
    const zero = spacetime([now.year(), now.month(), now.date(), hour, minute, 0, 0], timezone)
    const diff = this.props.now - zero.epoch
    return `${100 * (diff / (28 * 60 * 60 * 1000))}%`
  }

  private static BASE = 28 * 60
  _timeToPosition = (epoch: number, zero: number): number => {
    const value = Math.floor((epoch - zero) / 600)
    return Math.floor(value / Calendar.BASE) / 100
  }

  _scrollTo(index: number, left: number) {
    const entriesDiv = window.document.querySelector(".entries") as HTMLDivElement
    const item = entriesDiv.querySelector("li") as HTMLLIElement
    entriesDiv.scrollTo({
      left: left * item.offsetWidth,
      top: item.offsetHeight * index + (item.offsetHeight / 4.7) * index,
      behavior: "smooth"
    })
  }

  goToTips() {
    pageevent("entries", "go-to-tips")
    this.props.goToTips()
  }

  goToPreviousEntry(currentEntry: IEntry | null) {
    if (currentEntry === null) return
    if (!(typeof this.state.selectedEntryIndex !== "undefined" && typeof this.state.selectedIndex !== "undefined")) return

    let entryFound = currentEntry
    if (this.state.selectedEntryIndex === 0) {
      // When no more previous entries are in the day...
      let i = 0
      let p = 0
      // Find an entry from a slot that are in the past of the day
      do {
        i = i + 1
        // If no more entries of days are found, give up
        // NOTE that this could happen even if the entry is still there in the distant past
        // but the UI didn't scroll down enough to load them.
        // We don't worry about this for now, as most of the real users log continuously every day
        // which effectively prevents this from happening.
        if (this.state.selectedIndex + i >= this.state.entries.length) return
        const entries = this.state.entries[this.state.selectedIndex + i].entries
        p = entries.length
        if (p !== 0) {
          let j = entries.length - 1
          // Find the previous entry that is not the same entry that crosses a day, in the previous day
          do {
            entryFound = entries[j]
            j = j - 1
          } while (j >= 0 && entryFound.id === currentEntry.id)
        }
        // Loop while either a) the previous day had no entries (p=0); or b) the different entry was not found
      } while (p === 0 || entryFound.id === currentEntry.id)

      // Here the previous entry was found in the past from the `currentEntry`
      // Calculate the left position of the entry from its timestamp
      const left = this._timeToPosition(entryFound.timestamp, this.state.entries[this.state.selectedIndex + i].since)
      // Scroll to the position of the entry
      this._scrollTo(this.state.selectedIndex + i, left)
      // Set the new state to update the content of the modal
      this.setState({
        selectedIndex: this.state.selectedIndex + i,
        selectedEntryIndex: this.state.entries[this.state.selectedIndex + i].entries.length - 1
      })
    } else {
      // The day has another entry in the past
      // Calculate the left position of the entry from its timestamp
      const left = this._timeToPosition(this.state.entries[this.state.selectedIndex].entries[this.state.selectedEntryIndex - 1].timestamp, this.state.entries[this.state.selectedIndex].since)
      // Scroll to the position of the entry
      this._scrollTo(this.state.selectedIndex, left)
      // Set the new state to update the content of the modal
      this.setState({
        selectedEntryIndex: this.state.selectedEntryIndex - 1
      })
    }
  }

  goToNextEntry(currentEntry: IEntry | null) {
    if (currentEntry === null) return
    if (!(typeof this.state.selectedEntryIndex !== "undefined" && typeof this.state.selectedIndex !== "undefined")) return

    let entryFound = currentEntry
    if (this.state.selectedEntryIndex === this.state.entries[this.state.selectedIndex].entries.length - 1) {
      // When no more entries are in the day...
      let i = 0
      let p = 0
      // Find an entry from a slot that are in the future of the day
      do {
        i = i + 1
        // If no more entries of days are found, give up
        if (this.state.selectedIndex - i < 0) return
        const entries = this.state.entries[this.state.selectedIndex - i].entries
        p = entries.length
        if (p !== 0) {
          let j = 0
          // Find the next entry that is not the same entry that crosses a day, in the next day
          do {
            entryFound = entries[j]
            j = j + 1
          } while (j < p && entryFound.id === currentEntry.id)
        }
        // Loop while either a) the next day had no entries (p=0); or b) the different entry was not found
      } while (p === 0 || entryFound.id === currentEntry.id)

      // Here the next entry was found in the future from the `currentEntry`
      // Calculate the left position of the entry from its timestamp
      const left = this._timeToPosition(entryFound.timestamp, this.state.entries[this.state.selectedIndex - i].since)
      // Scroll to the position of the entry
      this._scrollTo(this.state.selectedIndex - i, left)
      // Set the new state to update the content of the modal
      this.setState({
        selectedIndex: this.state.selectedIndex - i,
        selectedEntryIndex: 0
      })
    } else {
      // The day has another entry in the future
      // Calculate the left position of the entry from its timestamp
      const left = this._timeToPosition(this.state.entries[this.state.selectedIndex].entries[this.state.selectedEntryIndex + 1].timestamp, this.state.entries[this.state.selectedIndex].since)
      // Scroll to the position of the entry
      this._scrollTo(this.state.selectedIndex, left)
      // Set the new state to update the content of the modal
      this.setState({
        selectedEntryIndex: this.state.selectedEntryIndex + 1
      })
    }
  }

  render() {
    if (this.state.isLoading && this.state.entries.length === 0) {
      return <Loading />
    }
    const showEntry = (typeof this.state.selectedIndex !== "undefined") && (typeof this.state.selectedEntryIndex !== "undefined")
    const selectedEntry = showEntry ? this.state.entries[this.state.selectedIndex!].entries[this.state.selectedEntryIndex!] : null
    const analytics = typeof this.state.selectedIndex === "undefined"
      ? <></>
      : <Analytics page={"/edit"} />
    const user = this.context.user
    return (<>
      <div className="time-header-container">
        <table className="time-header" cellSpacing={0} cellPadding={0}>
          <thead>
            <tr>
              {this._timeHeader(user.dayStarts)}
              <th className="summary head" colSpan={4}><span>Total Sleep</span></th>
            </tr>
          </thead>
        </table>
      </div>
      <div className="entries" onScroll={(e) => this._onScroll(e)}>
        <div className="calendar">
          <ul className="days" ref={(e) => this.onGotEntriesList(e)}>
            {
              this.state.entries.map((entry, index) => <Day key={index} now={this.props.now} index={index} entry={entry}
                selectedEntry={selectedEntry}
                onSelected={(ci, ei, e) => this.setState({ selectedIndex: ci, selectedEntryIndex: ei })} />)
            }
          </ul>
          <div className="day-header-container">
            <ul className="day-header">
              {
                this.state.entries.map((entry, index) => <DayHeader key={index} index={index} entry={entry} />)
              }
            </ul>
          </div>
          <div className="vertical-line-overlay-container">
            <table className="vertical-line-overlay" cellSpacing={0} cellPadding={0}>
              {/* This table header isn't visible by a CSS line. This hack is necessary to have time-header and vertical lines align. */}
              <thead>
                <tr>
                  {this._timeHeader(user.dayStarts)}
                  <th className="summary head" colSpan={4}>Total Sleep</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {this._scaleMarkers()}
                  <th className="summary offset" colSpan={4}></th>
                </tr>
              </tbody>
            </table>
            {/* -3px to put the line to the center of the dot. +2px to add the margin for the widths of the border. */}
            <div className="now-marker-point"
              style={{ left: `calc(${this._nowPosition(user.dayStarts, user.timezone)} - 3px + 2px)` }} />
            {/* +2px to add the margin for the widths of the border. */}
            <div className="now-marker-line"
              style={{ left: `calc(${this._nowPosition(user.dayStarts, user.timezone)} + 2px)` }} />
          </div>
        </div>
        <div className={`footer${this.state.isLoading ? " off" : ""}`}>
          <div className="footer-content">
            <div className="blurb">
              <p>That's all there is</p>
              <img alt="That's all there is" src="/empty-cat.svg" />
            </div>
            <div className="message">
              <p>Log easier. Check out our <button onClick={() => this.goToTips()} className="go-to-tips-button">tips and tricks</button>.</p>
            </div>
          </div>
        </div>
      </div>
      <ModalEntry
        onClose={() => this.setState({ selectedEntryIndex: undefined, selectedIndex: undefined })}
        onPrev={() => this.goToPreviousEntry(selectedEntry)}
        onNext={() => this.goToNextEntry(selectedEntry)}
        entry={selectedEntry}
        showEntry={showEntry} />
      {analytics}
    </>)
  }
}
