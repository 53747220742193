import React from "react"
import { IEntry } from "./Entry"
import { Category } from "./category"
import { formatTime, formatDateShort, formatDuration, parseTimeDisplay, formatDate } from "./datetime"
import "./Editor.css"
import spacetime from "spacetime"

interface EditorProps {
  entry: IEntry
  onCloseEditor: () => void
  onUpdateEntry: (entry: Partial<IEntry>) => void
  onDeleteEntry: () => void
  onGoToDay: (timestamp: number) => void
}

interface EditorState {
  isDirty: boolean
  category: Category
  rawBody: string
  timestamp: number
  duration: number
  saving: boolean
}

export default class Editor extends React.PureComponent<EditorProps, EditorState> {
  private body: HTMLTextAreaElement | null = null
  private dummyBody: HTMLDivElement | null = null
  constructor(props: EditorProps) {
    super(props)
    this.state = {
      isDirty: false,
      category: props.entry.category,
      rawBody: props.entry.rawBody,
      timestamp: props.entry.timestamp,
      duration: props.entry.duration,
      saving: false
    }
  }

  onGotDummyBody(dummyBody: HTMLDivElement | null) {
    if (dummyBody === null) return
    if (this.dummyBody === null) {
      this.dummyBody = dummyBody
    }
    this.setTextAreaHeight(dummyBody.offsetHeight)
  }

  onGotTextArea(body: HTMLTextAreaElement | null) {
    if (body === null) return
    if (this.body !== null) return
    this.body = body
    if (this.dummyBody !== null) {
      this.setTextAreaHeight(this.dummyBody.offsetHeight)
    }
  }

  componentDidMount() {
    if (this.dummyBody !== null) {
      this.setTextAreaHeight(this.dummyBody.offsetHeight)
    }
  }

  setTextAreaHeight(height: number) {
    if (this.body === null) return
    this.body.style.backgroundPositionY = `${height}px`
    this.body.style.height = `${height + 8}px`
  }

  /**
   * Converts CR/LF to <br>
   * Note that the method also converts any <> characters to characater references
   * because the return value of this method can be used by dangerouslySetInnerHTML.
   */
  replaceBreak(text: string): string {
    return text
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/\r\n|\n|\r/g, "<br>&nbsp;")
  }

  onEditRawBody(value: string) {
    this.setState({ isDirty: true, rawBody: value })
  }

  onEditStartDate(value: string) {
    const [year, month1, date] = value.split("-").map(v => parseInt(v))
    const currentDatetime = spacetime(this.state.timestamp, this.props.entry.timezone)
    const newDate = spacetime([year, month1 - 1, date, currentDatetime.hour(), currentDatetime.minute(), currentDatetime.second()], this.props.entry.timezone)
    this.setState({ isDirty: true, timestamp: newDate.epoch })
  }

  onEditStartTime(value: string) {
    const currentDatetime = spacetime(this.state.timestamp, this.props.entry.timezone)
    const newHourMinutes = parseTimeDisplay(value)
    const hour = Math.floor(newHourMinutes / 100)
    const minute = newHourMinutes - hour * 100
    let _newTimestamp = spacetime(
      [
        currentDatetime.year(),
        currentDatetime.month(),
        currentDatetime.date(),
        hour,
        minute,
        0
      ],
      this.props.entry.timezone)
    let newTimestamp = _newTimestamp.epoch

    if (newTimestamp >= this.state.timestamp + this.state.duration) {
      newTimestamp -= 24 * 60 * 60 * 1000
    }

    const newDuration = this.state.timestamp + this.state.duration - newTimestamp
    this.setState({ isDirty: true, timestamp: newTimestamp, duration: newDuration })
  }

  onEditEndTime(value: string) {
    const currentDatetime = spacetime(this.state.timestamp, this.props.entry.timezone)
    const newHourMinutes = parseTimeDisplay(value)
    const hour = Math.floor(newHourMinutes / 100)
    const minute = newHourMinutes - hour * 100
    let newTimestamp = spacetime(
      [
        currentDatetime.year(),
        currentDatetime.month(),
        currentDatetime.date(),
        hour,
        minute,
        0
      ],
      this.props.entry.timezone).epoch

    let newDuration = newTimestamp - this.state.timestamp
    if (newDuration <= 0) {
      newDuration = (newTimestamp + 24 * 60 * 60 * 1000) - this.state.timestamp
    }

    this.setState({ isDirty: true, duration: newDuration })
  }

  onEditCategory(value: Category) {
    this.setState({ isDirty: true, category: value })
  }

  onDiscard() {
    if (this.state.isDirty) {
      if (!window.confirm("You haven't saved your changes and they are going to be discarded. Are you sure?")) {
        return
      }
    }
    this.props.onCloseEditor()
  }

  onSave() {
    if (!this.state.isDirty) return
    this.setState({ saving: true })
    this.props.onUpdateEntry({
      rawBody: this.state.rawBody,
      category: this.state.category,
      timestamp: this.state.timestamp,
      duration: this.state.duration
    })
  }

  onDelete() {
    if (!window.confirm("You can't undo this action. Are you sure?")) {
      return
    }
    this.props.onDeleteEntry()
  }

  onGoToDay() {
    this.props.onGoToDay(this.props.entry.timestamp)
  }

  timeAndDateFieldsForTrackings() {
    const startTime = formatTime(this.state.timestamp, this.props.entry.timezone)
    const endTime = this.state.duration === 0
      ? ""
      : formatTime(this.state.timestamp + this.state.duration, this.props.entry.timezone)
    const date = formatDateShort(this.state.timestamp, this.props.entry.timezone, 0)
    const duration = formatDuration(this.state.duration)
    return (<>
      <div className="box">
        <div className="startTime">
          <label htmlFor="startTime">Start</label>
          <input type="time" id="startTime" defaultValue={startTime} onChange={(e) => { this.onEditStartTime(e.target.value) }} />
        </div>
        <div className="endTime">
          <label htmlFor="endTime">End</label>
          <input type="time" id="endTime" defaultValue={endTime} onChange={(e) => { this.onEditEndTime(e.target.value) }} />
        </div>
      </div>
      <div className="box">
        <div className="duration">
          <label htmlFor="duration">Duration</label>
          <input type="text" id="duration" disabled={true} value={duration} />
        </div>
        <div className="date">
          <label>
            <span>Date</span>
            <button className="link go-to-day" onClick={() => this.onGoToDay()}>Go to Day</button>
          </label>
          <input type="text" id="date" disabled={true} value={date} />
        </div>
      </div>
    </>)
  }

  timeAndDateFieldsForNotes() {
    const startTime = formatTime(this.state.timestamp, this.props.entry.timezone)
    const date = formatDate(this.state.timestamp, this.props.entry.timezone)
    return (<>
      <div className="box">
        <div className="startTime">
          <label htmlFor="startTime">Time</label>
          <input type="time" id="startTime" defaultValue={startTime} onChange={(e) => { this.onEditStartTime(e.target.value) }} />
        </div>
        <div className="startDate">
          <label htmlFor="date">Date</label>
          <input type="date" id="date" defaultValue={date} onChange={(e) => { this.onEditStartDate(e.target.value) }} />
        </div>
      </div>
      <div className="box-foot">
        <label>
          <button className="link go-to-day" onClick={() => this.onGoToDay()}>Go to Day</button>
        </label>
      </div>
    </>)
  }

  render() {
    return (
      <div className="editor-container">
        <div className={`editor category-${this.props.entry.category}`}>
          <div className="close">
            <button className="button close-button" onClick={() => this.onDiscard()}>
              <img alt="Close" src="/cross.svg" />
            </button>
          </div>
          <div className="editor-form">
            <div className="dummy-body" ref={(e) => this.onGotDummyBody(e)} dangerouslySetInnerHTML={{ __html: this.replaceBreak(this.state.rawBody) }} />
            <textarea className="body" value={this.state.rawBody} ref={(e) => this.onGotTextArea(e)}
              onChange={(e) => { this.onEditRawBody(e.target.value) }} />
            {this.state.category === Category.UNKNOWN ? this.timeAndDateFieldsForNotes() : this.timeAndDateFieldsForTrackings()}
            <div className={`category selected-${this.state.category}`}>
              <label htmlFor="category">Category</label>
              <select defaultValue={this.state.category} onChange={(e) => this.onEditCategory(parseInt(e.target.value))}>
                <option value={Category.ASLEEP}>Asleep</option>
                <option value={Category.AWAKE}>Awake</option>
                <option value={Category.PUT_DOWN}>Put Down</option>
                <option value={Category.UNKNOWN}>Notes</option>
              </select>
              <img alt="Dropdown" src="/down-dark.svg" className="dropdown-indicator" />
            </div>
            <div className="delete">
              <button className="button delete-button" onClick={() => this.onDelete()}>Delete Entry</button>
            </div>
          </div>
          <div className="footer" style={{ visibility: this.state.isDirty ? "visible" : "hidden" }}>
            <button className="button cancel-button" onClick={() => this.props.onCloseEditor()}>Cancel</button>
            <button className="button save-button" onClick={() => this.onSave()}>Save Changes</button>
          </div>
        </div>
        <div className="shade" style={{ display: this.state.saving ? "block" : "none" }}></div>
      </div>
    )
  }
}