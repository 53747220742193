import React from "react"
import { UserContext } from "./user"
import { TIMEZONES, addHours, parseTimeDisplay, timeNumberToTimeValue } from "./datetime"
import "./Setting.css"
import { pageevent, pageview } from "./ga"

interface SettingProps {
  onTips: () => void
}

interface SettingState {
  dayStarts: number
  timezone: string
}

export default class Setting extends React.PureComponent<SettingProps, SettingState> {
  static contextType = UserContext
  context!: React.ContextType<typeof UserContext>

  constructor(props: SettingProps) {
    super(props)
    this.state = {
      dayStarts: 0,
      timezone: "America/New_York"
    }
  }

  componentDidMount() {
    this.setState({
      dayStarts: this.context.user.dayStarts,
      timezone: this.context.user.timezone
    })
  }

  showToast(message: string) {
    const toast = window.document.querySelector(".toast") as HTMLDivElement
    if (toast === null) return
    toast.innerHTML = `<p>${message}</p>`
    toast.style.opacity = "1"
    setTimeout(() => {
      toast.style.opacity = "0"
      // 600ms is how long the opacity animation lasts. See App.css.
      setTimeout(() => toast.innerHTML = "", 600)
    }, 2000)
  }

  onSetTimezone(tz: string) {
    this.setTimezone(tz).then(_ => this.showToast("Timezone was updated."))
  }

  onSetDayStarts(e: React.ChangeEvent<HTMLInputElement>) {
    pageevent("setting", "update-dayStarts")
    const v = parseTimeDisplay(e.target.value)
    this.setState({ dayStarts: v })
    this.context.userDatabase.updateUser(this.context.user.id, { dayStarts: v })
      .then(user => this.context.updateUser(user))
      .then(_ => this.showToast("Start of a day was updated."))
  }

  shareLocation() {
    pageevent("setting", "share-location")
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
    if (!!tz) {
      this.setTimezone(tz).then(_ => this.showToast("Timezone was updated."))
    }
  }

  setTimezone(tz: string) {
    pageevent("setting", "update-timezone")
    this.setState({ timezone: tz })
    return this.context.userDatabase.updateUser(this.context.user.id, { timezone: tz })
      .then(user => this.context.updateUser(user))
  }

  onTips() {
    pageview("cheatsheet")
    this.props.onTips()
  }

  onExport() {
    pageevent("setting", "export")
  }

  render() {
    const dayStarts = typeof this.state.dayStarts === "undefined" ? 0 : this.state.dayStarts
    return (<div className="settings-container">
      <ul className="settings">
        <li>
          <h3>Time Zone</h3>
          <select value={this.state.timezone} onChange={(e) => this.onSetTimezone(e.target.value)}>
            {TIMEZONES[this.context.user.country].map(tz => <option key={tz.value} value={tz.value}>{tz.label}</option>)}
          </select>
          <img alt="Dropdown" src="/down-white.svg" className="dropdown-indicator"/>
          <p>Not sure? <button className="location" onClick={() => { this.shareLocation() }}>Share your location</button></p>
        </li>
        <li>
          <h3>Day start and end</h3>
          <p>Does it make more sense to see your data from morning to morning? Bedtime to bedtime? Set your preference here.</p>
          <div className="daytime">
            <div className="start-time">
              <label htmlFor="startTime">Day Starts</label>
              <input type="time" id="start-time" value={timeNumberToTimeValue(dayStarts)} onChange={(e) => { this.onSetDayStarts(e) }} />
            </div>
            <div className="end-time">
              <label htmlFor="endTime">Day Ends</label>
              <input type="time" id="end-time" disabled={true} value={timeNumberToTimeValue(addHours(dayStarts, 24))} />
            </div>
          </div>
        </li>
        <li>
          <button className="tips" onClick={() => this.onTips()}>Tips and tricks</button>
        </li>
        <li>
          <h3>Help and Feedback</h3>
          <p>We'd love to hear from you. Please email feedback or questions to <a className="feedback" href="mailto:tinylog@iandco.com">tinylog@iandco.com</a>.</p>
        </li>
        {/* <li>
        <button className="export" onClick={() => this.onExport()}>Export Data</button>
      </li> */}
      </ul>
      <div className="toast"></div>
    </div>)
  }
}
