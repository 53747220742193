import React from "react"
import { Category } from "./category"
import "./Filter.css"
import { parseTimeDisplay, addHours, timeNumberToTimeValue } from "./datetime"
import { Spacetime } from "spacetime"
import { UserContext } from "./user"

export enum TimeOfDay {
  NotSet,
  DayTime,
  NightTime,
  Custom
}

interface FilterProps {
  currentFilter: Filters
  onFilter: (filters: Filters) => void
  onCancel: () => void
}

interface FilterState {
  filter: Filters
  isDefault: boolean
}

export interface Filters {
  category: Category | null
  timeOfDay: TimeOfDay
  timeOfDayStarts: number | null,
  timeOfDayEnds: number | null,
  dateFrom: Spacetime | null,
  dateTo: Spacetime | null,
  sortBy: "timestamp" | "duration",
  sortOrder: "desc" | "asc"
}

export const DefaultFilters: Filters = {
  category: null,
  timeOfDay: TimeOfDay.NotSet,
  timeOfDayStarts: null,
  timeOfDayEnds: null,
  dateFrom: null,
  dateTo: null,
  sortBy: "timestamp",
  sortOrder: "desc"
}

export default class Filter extends React.PureComponent<FilterProps, FilterState> {
  static contextType = UserContext
  context!: React.ContextType<typeof UserContext>

  constructor(props: FilterProps) {
    super(props)
    const isFilterDefault = 
      props.currentFilter.category === DefaultFilters.category
      && props.currentFilter.timeOfDay === DefaultFilters.timeOfDay
      && props.currentFilter.sortBy === DefaultFilters.sortBy
      && props.currentFilter.sortOrder === DefaultFilters.sortOrder
    this.state = {
      filter: Object.assign({}, props.currentFilter),
      isDefault: isFilterDefault
    }
  }

  onGotElement(e: HTMLDivElement | null) {
    if (e === null) return
    const form = e.querySelector("form") as HTMLFormElement
    const footer = e.querySelector(".footer") as HTMLDivElement
    if (form === null || footer === null) return
    if (footer.offsetTop === 0) {
      form.style.height = `${form.offsetHeight - parseFloat(getComputedStyle(e).fontSize) * 6}px`
    } else {
      form.style.height = `${footer.offsetTop - parseFloat(getComputedStyle(e).fontSize) * 4}px`
    }
  }

  applyFilter(form: HTMLFormElement) {
    const category = form.querySelector("input[name=category]:checked") as HTMLInputElement
    const timeOfDay = form.querySelector("input[name=time_of_day]:checked") as HTMLInputElement
    const timeOfDayStarts = form.querySelector("#starts") as HTMLInputElement
    const timeOfDayEnds = form.querySelector("#ends") as HTMLInputElement
    const sort = form.querySelector("input[name=sort]:checked") as HTMLInputElement
    const [sortBy, sortOrder] = sort === null ? [null, null] : sort.value.split("-")
    const filters: Filters = {
      category: category === null ? null : parseInt(category.value),
      timeOfDay: timeOfDay === null ? TimeOfDay.NotSet : parseInt(timeOfDay.value),
      timeOfDayStarts: parseTimeDisplay(timeOfDayStarts.value),
      timeOfDayEnds: parseTimeDisplay(timeOfDayEnds.value),
      dateFrom: null,
      dateTo: null,
      // @ts-ignore MAKE SURE values of the "sort" radio buttons have corect values
      sortBy: sortBy,
      // @ts-ignore MAKE SURE values of the "sort" radio buttons have corect values
      sortOrder: sortOrder
    }
    this.props.onFilter(filters)
  }

  resetFilter(form: HTMLFormElement) {
    form.reset()
    const defaultFilter = Object.assign({}, DefaultFilters)
    this.setState({ filter: defaultFilter })
  }

  onChangeCustomStartTime(e: React.ChangeEvent<HTMLInputElement>) {
    try {
      const t = parseTimeDisplay(e.target.value)
      this.setState({ filter: { ...this.state.filter, timeOfDayStarts: t } })
    } catch (e) {
      window.alert("Cannot parse the time string. Enter something like 700 or 6pm.")
    }
  }

  onChangeCustomEndTime(e: React.ChangeEvent<HTMLInputElement>) {
    try {
      const t = parseTimeDisplay(e.target.value)
      this.setState({ filter: { ...this.state.filter, timeOfDayEnds: t } })
    } catch (e) {
      window.alert("Cannot parse the time string. Enter something like 700 or 6pm.")
    }
  }

  timeOfDayStrings(): [string, string] {
    if (this.state.filter.timeOfDay === TimeOfDay.Custom) {
      let timeOfDayStarts = 0
      let timeOfDayEnds = 2359
      if (this.state.filter.timeOfDayStarts !== null && this.state.filter.timeOfDayEnds !== null) {
        timeOfDayStarts = this.state.filter.timeOfDayStarts
        timeOfDayEnds = this.state.filter.timeOfDayEnds
      } else if (this.state.filter.timeOfDayStarts !== null) {
        timeOfDayStarts = this.state.filter.timeOfDayStarts
        timeOfDayEnds = addHours(timeOfDayStarts, 12)
      } else {
        timeOfDayStarts = this.context.user.dayStarts
        timeOfDayEnds = addHours(timeOfDayStarts, 12)
      }
      return [timeNumberToTimeValue(timeOfDayStarts), timeNumberToTimeValue(timeOfDayEnds)]
    } else if (this.state.filter.timeOfDay === TimeOfDay.DayTime) {
      const userDayStarts = this.context.user.dayStarts
      return [timeNumberToTimeValue(userDayStarts), timeNumberToTimeValue(addHours(userDayStarts, 12))]
    } else if (this.state.filter.timeOfDay === TimeOfDay.NightTime) {
      const userDayStarts = this.context.user.dayStarts
      return [timeNumberToTimeValue(addHours(userDayStarts, 12)), timeNumberToTimeValue(userDayStarts)]
    } else {
      return ["", ""]
    }
  }

  onFilterChange(newFilter: Filters) {
    this.setState({ filter: newFilter, isDefault: false })
  }

  render() {
    const [timeOfDayStarts, timeOfDayEnds] = this.timeOfDayStrings()
    return (<div className="filter" ref={(e) => this.onGotElement(e)}>
      <form>
        <h2>Filter by</h2>
        <div className="box">
          <h3>Category</h3>
          <div>
            <label className="toggle">
              <input type="radio" name="category" value={Category.ASLEEP} checked={this.state.filter.category === Category.ASLEEP}
                onChange={() => this.onFilterChange({ ...this.state.filter, category: Category.ASLEEP })} />
              <span className="category-1">Asleep</span>
            </label>
            <label className="toggle">
              <input type="radio" name="category" value={Category.AWAKE} checked={this.state.filter.category === Category.AWAKE}
                onChange={() => this.onFilterChange({ ...this.state.filter, category: Category.AWAKE })} />
              <span className="category-2">Awake</span>
            </label>
            <label className="toggle">
              <input type="radio" name="category" value={Category.PUT_DOWN} checked={this.state.filter.category === Category.PUT_DOWN}
                onChange={() => this.onFilterChange({ ...this.state.filter, category: Category.PUT_DOWN })} />
              <span className="category-3">Put Down</span>
            </label>
            <label className="toggle">
              <input type="radio" name="category" value={Category.UNKNOWN} checked={this.state.filter.category === Category.UNKNOWN}
                onChange={() => this.onFilterChange({ ...this.state.filter, category: Category.UNKNOWN })} />
              <span className="category-0">Notes</span>
            </label>
          </div>
        </div>
        <div className="box">
          <h3>Time of Day</h3>
          <div>
            <label className="toggle">
              <input type="radio" name="time_of_day" value={TimeOfDay.DayTime} checked={this.state.filter.timeOfDay === TimeOfDay.DayTime}
                onChange={() => this.onFilterChange({ ...this.state.filter, timeOfDay: TimeOfDay.DayTime })} />
              <span>Daytime</span>
            </label>
            <label className="toggle">
              <input type="radio" name="time_of_day" value={TimeOfDay.NightTime} checked={this.state.filter.timeOfDay === TimeOfDay.NightTime}
                onChange={() => this.onFilterChange({ ...this.state.filter, timeOfDay: TimeOfDay.NightTime })} />
              <span>Nighttime</span>
            </label>
            <label className="toggle">
              <input type="radio" name="time_of_day" value={TimeOfDay.Custom} checked={this.state.filter.timeOfDay === TimeOfDay.Custom}
                onChange={() => this.onFilterChange({ ...this.state.filter, timeOfDay: TimeOfDay.Custom })} />
              <span className="custom">Custom</span>
            </label>
          </div>
          <div className={`custom-time${this.state.filter.timeOfDay === TimeOfDay.Custom ? "" : " off"}`}>
            <div className="start-time">
              <input type="time" id="starts" value={timeOfDayStarts} onChange={(e) => this.onChangeCustomStartTime(e)} />
            </div>
            <div className="separator">
              &mdash;
            </div>
            <div className="end-time">
              <input type="time" id="ends" value={timeOfDayEnds} onChange={(e) => this.onChangeCustomEndTime(e)} />
            </div>
          </div>
        </div>
        <hr />
        <h2>Sort by</h2>
        <div className="box">
          <div className="sort-option">
            <label className="circle">
              <input type="radio" name="sort" id="sort-timestamp-desc" value="timestamp-desc" defaultChecked={
                this.state.filter.sortBy === "timestamp" && this.state.filter.sortOrder === "desc"
              } onChange={() => this.setState({ isDefault: false })} />
              <span className="image" />
            </label>
            <label htmlFor="sort-timestamp-desc">Newest to Oldest</label>
          </div>
          <div className="sort-option">
            <label className="circle">
              <input type="radio" name="sort" id="sort-timestamp-asc" value="timestamp-asc" defaultChecked={
                this.state.filter.sortBy === "timestamp" && this.state.filter.sortOrder === "asc"
              } onChange={() => this.setState({ isDefault: false })} />
              <span className="image" />
            </label>
            <label htmlFor="sort-timestamp-asc">Oldest to Newest</label>
          </div>
          <div className="sort-option">
            <label className="circle">
              <input type="radio" name="sort" id="sort-duration-desc" value="duration-desc" defaultChecked={
                this.state.filter.sortBy === "duration" && this.state.filter.sortOrder === "desc"
              } onChange={() => this.setState({ isDefault: false })} />
              <span className="image" />
            </label>
            <label htmlFor="sort-duration-desc">Duration: Longest to shortest</label>
          </div>
          <div className="sort-option">
            <label className="circle">
              <input type="radio" name="sort" id="sort-duration-asc" value="duration-asc" defaultChecked={
                this.state.filter.sortBy === "duration" && this.state.filter.sortOrder === "asc"
              } onChange={() => this.setState({ isDefault: false })} />
              <span className="image" />
            </label>
            <label htmlFor="sort-duration-asc">Duration: Shortest to longest</label>
          </div>
        </div>
      </form>
      <div className={`footer${this.state.isDefault ? " pristine" : ""}`}>
        <button onClick={() => this.applyFilter(document.forms[0])} className="apply-button">Apply</button>
        <button onClick={() => this.resetFilter(document.forms[0])} className="clear-button">Clear Filters</button>
      </div>
      <button className="close-button" onClick={() => this.props.onCancel()}>
        <img alt="Close" src="/cross.svg" />
      </button>
    </div>)
  }
}