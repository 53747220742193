import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { ApiUserDatabase, UserDatabase, UserStatus } from "./user"
import { setUserId } from "./ga"
import Loading from "./Loading"
import Textline from "./Textline"
import { ApiDatabase } from "./database"
import Homepage from "./Homepage"
import Helppage from "./Helppage"
const smoothscroll = require('smoothscroll-polyfill')

const backend = new ApiUserDatabase()
const userDb = new UserDatabase(backend)
const id = window.location.pathname.substring(1)

function route(id: string | undefined | null) {
  // Simple router
  if (typeof id === "undefined" || id === null || id.length === 0) {
    ReactDOM.render((<Homepage />), document.getElementById("root"))
  } else if (id === "help") {
    const country = window.location.hash.substring(1)
    ReactDOM.render((<Helppage country={country} />), document.getElementById("root"))
  } else if (id === "textline") {
    ReactDOM.render(<Textline
        userId={window.location.hash.substring(1)}
        onBack={(userId) => window.location.href=`/${userId}`}
        width={window.innerWidth * 1.5}
        height={window.innerHeight} />
      , document.getElementById("root"))
  } else {
    userDb.loadUser(id).then(user => {
      if (user.status === UserStatus.ONBOARDED) {
        // When the user is in the middle of onboarding and the user was in a
        // different timezone than was detected from their phone number,
        // update the timezone setting. Only here, for now.
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
        if (user.timezone !== tz) {
          return userDb.updateUser(id, { timezone: tz })
        }
      }
      return Promise.resolve(user)
    }).then(user => {
      setUserId(id)
      const db = new ApiDatabase(user)
      ReactDOM.render(<App user={user} database={db} userDatabase={userDb} />, document.getElementById("root"))
    }).catch(e => {
      ReactDOM.render((<p>{e.message}</p>), document.getElementById("root"))
    })
    ReactDOM.render((<Loading />), document.getElementById("root"))
  }
}

route(id)
smoothscroll.polyfill()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
