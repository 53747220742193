import React from "react"
import "./Navbar.css"
import { UserContext } from "./user"
import { pageevent } from "./ga"

export enum ViewMode {
  List,
  Timeline
}

interface NavbarProps {
  isSettingOn: boolean
  viewMode: ViewMode
  onHome: (viewMode: ViewMode) => void
  onSettings: () => void
}

export default function Navbar(props: NavbarProps) {
  return (
    <UserContext.Consumer>
      {({ user }) => (
        <nav className="nav">
          <div className="left">
            <button className="icon" onClick={() => {
              pageevent("click", "switch-view", "list")
              props.onHome(ViewMode.List)
            }}>
              <img alt="List" src={
                props.isSettingOn
                  ? "/images/home-off.svg"
                  : props.viewMode === ViewMode.List
                    ? "/images/home-on.svg"
                    : "/images/home-off.svg"
              } />
            </button>
            <button className={`icon${user.didUseCalendar || props.isSettingOn || props.viewMode === ViewMode.Timeline ? "" : " new"}`} onClick={() => {
              pageevent("click", "switch-view", "timeline")
              props.onHome(ViewMode.Timeline)
            }}>
              <img alt="Timeline" src={
                props.isSettingOn
                ? "/images/timeline-off.svg"
                : props.viewMode === ViewMode.Timeline
                  ? "/images/timeline-on.svg"
                  : "/images/timeline-off.svg"
            } />
              <div><span>New</span></div>
            </button>
          </div>
          <div className="right">
            <button className="icon" onClick={() => {
              props.onSettings()
            }}>
              <img alt="Settings" src={props.isSettingOn ? "/setting-on.svg" : "/setting-off.svg"} />
            </button>
            <a className="icon" href={`/textline#${user.id}`} onClick={() => pageevent("click", "textline")}>
              <img alt="Tiny Textline" src="/textline-nav.svg" />
            </a>
          </div>
        </nav>
      )}
    </UserContext.Consumer>
  )
}