import React from "react"

export default function HomepageFooter() {
  return (
    <footer className="footer">
      <p>For questions and help please email us at <a href="mailto:tinylog@iandco.com">tinylog@iandco.com</a></p>
      <p>Tiny Log was proudly created by the&nbsp;Incubator&nbsp;at&nbsp;<a href="https://iandco.com">I&amp;CO</a></p>
      <p>*Msg &amp; data rates may apply.</p>
    </footer>
  )
}