import React from "react"
import Entry, { IEntry } from "./Entry"
import "./ModalEntry.css"

interface ModalEntryProps {
  showEntry: boolean
  entry: IEntry | null
  onClose: () => void
  onPrev: () => void
  onNext: () => void
}

export default function ModalEntry(props: ModalEntryProps) {
  return (
    <div className={`modal-entry${props.showEntry ? " on" : ""}`}
      onClick={props.onClose}
    >
      <div className="subnav" onClick={(e) => e.stopPropagation()}>
        <button className="prev" onClick={(e) => {
          e.stopPropagation()
          props.onPrev()
        }}>
          <img src="/images/arrow-prev.svg" alt="previous entry" />
        </button>
        <button className="next" onClick={(e) => {
          e.stopPropagation()
          props.onNext()
        }}>
          <img src="/images/arrow-next.svg" alt="next entry" />
        </button>
      </div>
      <ul onClick={(e) => e.stopPropagation()}>
        { props.entry === null ? null : <Entry entry={props.entry} /> }
      </ul>
    </div>
  )
}