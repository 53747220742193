import React, { useContext } from "react"
import "./Tips.css"
import { UserContext } from "./user"
import { NUMBERS } from "./phone_numbers"
import Help from "./Help"

interface TipsProps {
  onBack: () => void
}

export default function Tips(props: TipsProps) {
  const userContext = useContext(UserContext)
  const phoneNumber = NUMBERS[userContext.user.country]
  
  return (<div className="tips">
    <button className="back" onClick={() => props.onBack()}>
      <img alt="Go back" src="/arrow-left.svg" />
    </button>
    <Help className="content" phoneNumber={phoneNumber} />
  </div>)
}