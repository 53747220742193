import React from "react"
import { CalendarEntry } from "./Calendar";
import spacetime from "spacetime";

interface DayHeaderProps {
  index: number
  entry: CalendarEntry
}

export default function DayHeader(props: DayHeaderProps) {
  const date = spacetime(props.entry.since)
  return (
    <li className="date">{date.format("day-short")}<br/>{date.format("date")}</li>
  )
}