import React from "react"
import DateHeader from "./DateHeader"
import FilterHeader from "./FilterHeader"
import { Filters, TimeOfDay } from "./Filter"

interface HeaderProps {
  filterable: boolean
  date: string
  totalSleep: number
  filter: Filters
  onFilter: () => void
  onClearFilter: () => void
}

export default function Header(props: HeaderProps) {
  const isFilterOn = props.filter.category !== null || props.filter.timeOfDay !== TimeOfDay.NotSet || props.filter.dateFrom !== null
  return (
    <div className="date-header">
      {
        isFilterOn
          ? <FilterHeader filter={props.filter} onClearFilter={props.onClearFilter} />
          : <DateHeader date={props.date} totalSleep={props.totalSleep} />
      }
      {
        props.filterable
          ? <button className="icon filter-button" onClick={() => {
              props.onFilter()
            }}>
              <img alt="Filter" src={isFilterOn ? "/filter-on.svg" : "/filter-off.svg"} />
            </button>
          : null
      }
    </div>
  )
}