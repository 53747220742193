export interface Message {
  id: string
  body: string
  created: number
}

interface Rect {
  x: number
  y: number
  width: number
  height: number
}

const NUM_COLORS = 3
const SPEED = 1

export class TextlineView {
  private document: HTMLDocument
  private ul: HTMLUListElement
  private width: number
  private height: number
  private itemWidths: number[]
  private virtualScreen: { [id: string]: Rect }
  private onHoldMessage: (id: string) => void
  constructor(document: HTMLDocument, ul: HTMLUListElement, width: number, height: number, itemWidths: number[], onHoldMessage: (id: string) => void) {
    this.document = document
    this.ul = ul
    this.width = width
    this.height = height
    this.itemWidths = itemWidths
    this.onHoldMessage = onHoldMessage
    this.virtualScreen = {}
  }

  add(message: Message) {
    const li = this._createListItem(message)
    li.addEventListener("touchstart", (e) => this._onHoldMessage(e), false)
    li.addEventListener("touchend", this._onReleaseMessage, false)
    this.ul.appendChild(li)
  }

  onNewMessage(message: Message) {
    this.add(message)
  }

  onMessageRemoved(id: string) {
    const li = this.ul.querySelector(`#${id}`)
    if (li === null) return
    li.removeEventListener("touchstart", this._onHoldMessage as EventListener)
    li.removeEventListener("touchend", this._onReleaseMessage as EventListener)
    this.ul.removeChild(li)
  }

  animate() {
    this.ul.querySelectorAll("li").forEach(li => {
      if (li.getAttribute("data-holding") === null) {
        let top = parseInt(li.style.top) - SPEED
        if (top < -li.offsetHeight) {
          const font = this._fontSize(li.innerHTML)
          const rect = this._positionMessage(li.id, Date.now(), font)
          li.style.left = `${rect.x}px`
          li.style.top = `${rect.y + this.height}px`
        } else {
          li.style.top = `${top}px`
        }
        li.style.zIndex = "0"
      }
    })
    window.requestAnimationFrame(() => this.animate())
  }

  // Hit test of the currently visible notes and the new note
  // Used to put the new note farther enough to the existing ones
  _rectCollidesWithOthers(id: string, rect: Rect): boolean {
    const rects = Object.keys(this.virtualScreen).slice(0)
    for (const key of rects) {
      // If the hittest is about itself, just skip
      if (key === id) continue;
      const r = this.virtualScreen[key]
      // If one of these four conditions are met, the two rects don't collide.
      // Check the next one
      if (rect.x > r.x + r.width) continue
      if (rect.y > r.y + r.height) continue
      if (rect.x + rect.width < r.x) continue
      if (rect.y + rect.height < r.y) continue
      // If the execution arrives here, the two rects collide.
      return true
    }
    // If the execution arrives here, the `rect` did not collide with any other
    return false
  }

  _fontSize(body: string): number {
    return body.replace(/\s/g, "").length === body.length
      ? 0
      : body.length > 100
        ? 1
        : body.length > 70
          ? 2
          : body.length > 30
            ? 3
            : 4
  }

  _positionMessage(id: string, factor: number, fontSize: number): Rect {
    let rect = {
      x: (Math.random() * factor) % (this.width - this.itemWidths[fontSize]),
      y: (Math.random() * factor) % this.height,
      width: this.itemWidths[fontSize],
      height: this.itemWidths[fontSize] * (4 / 7)
    }

    // Check if the new rect collides with the existing ones.
    // If it does, move the new one to the left and try again.
    // If 10 moves didn't help, just give up and move on to avoid infinite hit testings.
    for (let i = 0; i < 10; i++) {
      if (!this._rectCollidesWithOthers(id, rect)) {
        this.virtualScreen[id] = rect
        return rect
      }
      let x = rect.x + this.itemWidths[fontSize] / 2
      if (x > 3 * this.width / 4) x = 0
      rect = {
        x: x,
        y: rect.y + rect.height / 2,
        width: rect.width,
        height: rect.height
      }
    }

    // Check if the new rect collides with the existing ones again.
    // But this time, move the new one towards the bottom and try again if it does collide.
    // If 10 moves didn't help, just give up and move on to avoid infinite hit testings.
    for (let i = 0; i < 10; i++) {
      if (!this._rectCollidesWithOthers(id, rect)) {
        this.virtualScreen[id] = rect
        return rect
      }
      let y = rect.y + rect.height / 2
      rect = {
        x: (Math.random() * factor) % (this.width - this.itemWidths[fontSize]),
        y: y,
        width: rect.width,
        height: rect.height
      }
    }

    // This rect is going to collide with another one, but we can't do anything about it.
    this.virtualScreen[id] = rect
    return rect
  }

  _createListItem(message: Message) {
    const color = Math.floor(message.created % NUM_COLORS)
    const font = this._fontSize(message.body)
    const rect = this._positionMessage(message.id, message.created, font)
    const li = this.document.createElement("li")
    li.id = message.id
    li.className = `message color-${color} font-${font}`
    li.style.left = `${rect.x}px`
    li.style.top = `${rect.y}px`
    li.style.width = `${rect.width}px`
    li.style.minHeight = `${rect.height}px`
    li.appendChild(this.document.createTextNode(message.body))
    return li
  }

  _onHoldMessage(e: TouchEvent) {
    if (e.target === null) return
    const li = e.target as HTMLLIElement
    let zIndex = parseInt(li.style.zIndex)
    if (isNaN(zIndex)) zIndex = 0
    li.style.zIndex = (zIndex + 10) + ""
    li.setAttribute("data-holding", "true")
    this.onHoldMessage(li.id)
  }

  _onReleaseMessage(e: TouchEvent) {
    if (e.target === null) return
    const li = e.target as HTMLLIElement
    if (li.getAttribute("data-holding") === null) return
    li.style.zIndex = "0"
    li.removeAttribute("data-holding")
  }
}