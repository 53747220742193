import React from "react"
import Entry, { IEntry } from "./Entry"
import Editor from "./Editor"
import "./Entries.css"
import { Category } from "./category"
import { formatDateShort, formatDateLong } from "./datetime"
import { Filters } from "./Filter"
import Analytics from "./Analytics"
import { pageevent } from "./ga"
import Loading from "./Loading"
import { UserContext } from "./user"

interface EntriesProps {
  filter: Filters
  onDateChanged: (date: string, totalSleep: number) => void
  goToDay: (timestamp: number) => void
  goToTips: () => void
}

interface EntriesState {
  entries: IEntry[]
  moreLoadedAt?: string
  selectedIndex?: number
  isLoading: boolean
}

// If 3 for example, "loadMore" kicks in when the scrolling reaches the third to the last element at the bottom.
const LOAD_MORE_THRESHOLD = 3

export default class Entries extends React.PureComponent<EntriesProps, EntriesState> {
  static contextType = UserContext
  context!: React.ContextType<typeof UserContext>
  private currentIndex: number
  private currentScrollTop: number
  private currentDate: string
  private totalSleeps: { [key: string]: number }
  private currentTotalSleep: number

  constructor(props: EntriesProps) {
    super(props)
    this.currentIndex = 0
    this.currentScrollTop = 0
    this.currentDate = ""
    this.totalSleeps = {}
    this.currentTotalSleep = 0
    this.state = {
      entries: [],
      isLoading: false
    }
  }

  componentDidMount() {
    this._loadFirst()
  }

  componentDidUpdate(prevProps: EntriesProps) {
    if (prevProps.filter !== this.props.filter) {
      this._loadFirst()
    }
  }

  onGotEntriesList(e: HTMLUListElement | null) {
    if (e === null) return
    // HACK: remove the bottom bar height from the entries list
    // so you can see the very bottom on top of the bottom bar in iOS Safari or Android Chrome.
    e.parentElement!.style.height = (window.innerHeight - e.parentElement!.offsetTop) + "px"
  }

  _loadFirst() {
    this.totalSleeps = {}
    this.currentDate = ""
    this.currentTotalSleep = 0
    this.setState({ entries: [], isLoading: true })
    this.context.database.loadTrackings(this.props.filter, null).then(entries => {
      if (entries.length === 0) {
        this.setState({ isLoading: false })
        return
      }
      this._processEntries(entries)
      this.props.onDateChanged(formatDateLong(entries[0].timestamp, entries[0].timezone, this.context.user.dayStarts), this.currentTotalSleep)
      this.setState({ entries: entries, isLoading: false })
    })
  }

  _loadMore() {
    this.setState({ isLoading: true })
    const lastEntry = this.state.entries[this.state.entries.length - 1]
    this.context.database.loadTrackings(this.props.filter, lastEntry[this.props.filter.sortBy]).then(entries => {
      if (entries.length === 0) {
        this.setState({ moreLoadedAt: lastEntry.id, isLoading: false })
        return
      }
      this._processEntries(entries)
      this.setState({
        entries: [...this.state.entries, ...entries],
        moreLoadedAt: lastEntry.id,
        isLoading: false
      })
    })
  }

  _processEntries(entries: IEntry[]) {
    entries.forEach((entry, index) => {
      const date = formatDateShort(entry.timestamp, entry.timezone, this.context.user.dayStarts)
      if (index === 0) this.currentDate = date
      if (entry.category !== Category.ASLEEP) return
      if (date === this.currentDate) this.currentTotalSleep += entry.duration
      if (!!this.totalSleeps[date]) {
        this.totalSleeps[date] += entry.duration
      } else {
        this.totalSleeps[date] = entry.duration
      }
    })
  }

  /**
   * Returns the total duration of sleep time by date.
   * The data has been already cached in to an object in the constructor.
   */
  _totalSleepOf(entry: IEntry): number {
    const date = formatDateShort(entry.timestamp, entry.timezone, this.context.user.dayStarts)
    const totalSleep = this.totalSleeps[date]
    if (totalSleep === null || typeof totalSleep === "undefined") return 0
    return totalSleep
  }

  /**
   * Returns the index of the current first visible HTMLLiElement and the current scrollTop property of the container
   */
  _findTopEntry(div: HTMLDivElement): [number, number] | null {
    if (div.scrollTop === 0) return [0, 0]

    // TODO can we cache this?
    const listItems = div.querySelectorAll("li")

    // We usually want to start searching for the current first visible entry from the previous position...
    let i = this.currentIndex
    if (this.currentScrollTop > div.scrollTop) {
      // But if we are scrolling up, start searching for the element from entries a little above from the previous one
      i = Math.max(i - 10, 0)
    }
    for (; i < listItems.length; i++) {
      const li = listItems[i]
      if (div.scrollTop > li.offsetTop && div.scrollTop < li.offsetTop + li.offsetHeight) {
        return [i, div.scrollTop]
      }
    }
    return null
  }

  _onScroll(e: React.UIEvent<HTMLDivElement>) {
    if (this.state.isLoading) return
    const div = e.target as HTMLDivElement
    const scrollingUp = this.currentScrollTop > div.scrollTop
    this._checkDateChange(div)
    const canLoadMore = this.state.entries.length === 0
      || this.state.moreLoadedAt !== this.state.entries[this.state.entries.length - 1].id
    if (!scrollingUp && canLoadMore) {
      this._checkAtBottom(div)
    }
  }

  _checkDateChange(div: HTMLDivElement) {
    const found = this._findTopEntry(div)
    if (found === null) return
    if (found[0] === this.currentIndex) return

    const topEntry = this.state.entries[found[0]]
    const date = formatDateShort(topEntry.timestamp, topEntry.timezone, this.context.user.dayStarts)
    this.currentIndex = found[0]
    this.currentScrollTop = found[1]
    if (date !== this.currentDate) {
      this.currentDate = date
      this.currentTotalSleep = this._totalSleepOf(topEntry)
    }
    this.props.onDateChanged(formatDateLong(topEntry.timestamp, topEntry.timezone, this.context.user.dayStarts), this.currentTotalSleep)
  }

  _checkAtBottom(div: HTMLDivElement) {
    if (div.scrollTop === 0) return
    const listItems = div.querySelectorAll("li")
    if (listItems === null || listItems.length < LOAD_MORE_THRESHOLD) return

    if (div.scrollTop + div.offsetHeight > listItems[listItems.length - LOAD_MORE_THRESHOLD - 1].offsetTop - div.offsetTop) {
      this._loadMore()
    }
  }

  updateEntry(data: Partial<IEntry>) {
    if (typeof this.state.selectedIndex === "undefined") return
    const entry = this.state.entries[this.state.selectedIndex]
    this.context.database.updateTracking(entry.id, data).then(newEntry => {
      if (typeof this.state.selectedIndex === "undefined") return
      const entries = this.state.entries.slice(0)
      entries.splice(this.state.selectedIndex, 1, newEntry)
      this.setState({ entries: entries, selectedIndex: undefined })
    })
    pageevent("editEntry", "update")
  }

  deleteEntry() {
    if (typeof this.state.selectedIndex === "undefined") return
    if (typeof this.state.selectedIndex === "undefined") return
    const entry = this.state.entries[this.state.selectedIndex]
    this.context.database.deleteTracking(entry.id).then(_ => {
      if (typeof this.state.selectedIndex === "undefined") return
      const entries = this.state.entries.slice(0)
      entries.splice(this.state.selectedIndex, 1)
      this.setState({ entries: entries, selectedIndex: undefined })
    })
    pageevent("editEntry", "delete")
  }

  cancelEdit() {
    pageevent("editEntry", "cancel")
    this.setState({ selectedIndex: undefined })
  }

  goToDay(timestamp: number) {
    this.setState({ selectedIndex: undefined })
    this.props.goToDay(timestamp)
  }

  goToTips() {
    pageevent("entries", "go-to-tips")
    this.props.goToTips()
  }

  render() {
    if (this.state.isLoading && this.state.entries.length === 0) {
      return <Loading />
    }
    const editor = typeof this.state.selectedIndex === "undefined"
      ? <></>
      : <Editor
        entry={this.state.entries[this.state.selectedIndex]}
        onCloseEditor={() => this.cancelEdit()}
        onUpdateEntry={(entry) => this.updateEntry(entry)}
        onDeleteEntry={() => this.deleteEntry()}
        onGoToDay={(timestamp) => this.goToDay(timestamp)} />
    const analytics = typeof this.state.selectedIndex === "undefined"
      ? <></>
      : <Analytics page={"/edit"} />
    return (<>
      <div className="entries" onScroll={(e) => this._onScroll(e)}>
        <ul className="list" ref={(e) => this.onGotEntriesList(e)}>
          {
            this.state.entries.map((entry, index) => <Entry key={entry.id} entry={entry} onClick={() => this.setState({ selectedIndex: index })} />)
          }
        </ul>
        <div className={`footer${this.state.isLoading ? " off" : ""}`}>
          <div className="blurb">
            <p>That's all there is</p>
            <img alt="That's all there is" src="/empty-cat.svg" />
          </div>
          <div className="message">
            <p>Log easier. Check out our <button onClick={() => this.goToTips()} className="go-to-tips-button">tips and tricks</button>.</p>
          </div>
        </div>
      </div>
      {editor}
      {analytics}
    </>)
  }
}
