import React from "react"
import "./App.css"
import Navbar, { ViewMode } from "./Navbar"
import Home from "./Home"
import Filter, { Filters, DefaultFilters } from "./Filter"
import Setting from "./Setting"
import Tips from "./Tips"
import { User, UserDatabase, UserContext } from "./user"
import { pageview } from "./ga"
import spacetime from "spacetime"
import { IDatabase } from "./database"

interface AppProps {
  user: User
  database: IDatabase
  userDatabase: UserDatabase
}

interface AppState {
  user: User
  filtering: boolean
  currentFilter: Filters
  setting: boolean
  tips: boolean
  tipsBackToSetting: boolean
  homeViewMode: ViewMode
}

const LOCALSTORAGE_KEY_HOME_VIEW_MODE = "tinyLogHomeViewMode"

export default class App extends React.Component<AppProps, AppState> {
  private previousSub: boolean

  constructor(props: AppProps) {
    super(props)
    const viewMode = sessionStorage.getItem(LOCALSTORAGE_KEY_HOME_VIEW_MODE)
    this.state = {
      user: this.props.user,
      filtering: false,
      currentFilter: Object.assign({}, DefaultFilters),
      setting: false,
      tips: false,
      tipsBackToSetting: false,
      homeViewMode: viewMode === null
        ? ViewMode.List
        : parseInt(viewMode)
    }
    this.previousSub = this.state.filtering
  }

  // See if we are runningin PWA mode
  isPwa() {
    // @ts-ignore navigator.standalone is there for sure.
    if ("standalone" in window.navigator && window.navigator.standalone) {
      //Safari
      return true
    } else if (window.matchMedia("(display-mode: standalone)").matches) {
      //Chrome
      return true
    } else {
      return false
    }
  }

  onGoToDay(timestamp: number) {
    const filter = Object.assign({}, DefaultFilters)
    filter.dateFrom = spacetime(timestamp, this.state.user.timezone)
    filter.timeOfDayStarts = this.state.user.dayStarts
    this.setState({ currentFilter: filter })
  }

  render() {
    const main = this.state.setting
      ? <Setting
        onTips={() => this.setState({ setting: false, tips: true, tipsBackToSetting: this.state.setting })} />
      : this.state.tips
        ? <Tips
          onBack={() => this.setState({ setting: this.state.tipsBackToSetting, tips: false })}
          />
        : <Home
          filter={this.state.currentFilter}
          isPWA={this.isPwa()}
          viewMode={this.state.homeViewMode}
          onFilter={() => { this.setState({ filtering: true }) }}
          onClearFilter={() => { this.setState({ currentFilter: Object.assign({}, DefaultFilters) }) }}
          onGoToDay={(timestamp) => this.onGoToDay(timestamp)}
          onGoToTips={() => this.setState({ setting: false, tips: true, tipsBackToSetting: this.state.setting })} />

    // XXX: Ugly hack to do pageview analytics...
    // TODO: There has to be a better way than this or "react-ga"
    pageview(this.state.setting ? "/setting" : this.state.tips ? "/tips" : this.state.homeViewMode === ViewMode.Timeline ? "/timeline" : "/home")
    if (this.previousSub !== this.state.filtering && this.state.filtering) {
      pageview("/filter")
    }
    this.previousSub = this.state.filtering

    return (
      <div className="App">
        <UserContext.Provider value={{
          user: this.state.user,
          userDatabase: this.props.userDatabase,
          database: this.props.database,
          updateUser: (user) => { this.setState({ user: user })}
        }}>
        <div className="main">
          <Navbar
            isSettingOn={this.state.setting || this.state.tips}
            viewMode={this.state.homeViewMode}
            onHome={viewMode => {
              sessionStorage.setItem(LOCALSTORAGE_KEY_HOME_VIEW_MODE, `${viewMode}`)
              if (this.isPwa() && viewMode === this.state.homeViewMode) {
                // HACK: if we are in PWA, allow user to tap on the home button again
                // to reload the page because Safari doesn't allow users to refresh the page.
                if (!this.state.filtering && !this.state.setting && !this.state.tips) {
                  window.location.reload(true)
                }
              }
              this.setState({ filtering: false, setting: false, tips: false, homeViewMode: viewMode })
              if (!this.state.user.didUseCalendar && viewMode === ViewMode.Timeline) {
                this.props.userDatabase.updateUser(this.state.user.id, { didUseCalendar: true })
                  .then(user => this.setState({ user: user }))
              }
            }}
            onSettings={() => this.setState({ filtering: false, setting: true, tips: false })}
          />
          {main}
        </div>
        { this.state.homeViewMode === ViewMode.Timeline
          ? null
          : <div className={`sub ${this.state.filtering ? "on" : ""}`}>
              <Filter key={JSON.stringify(this.state.currentFilter)} currentFilter={this.state.currentFilter}
                onCancel={() => this.setState({ filtering: false })}
                onFilter={(filter) => this.setState({ currentFilter: filter, filtering: false })} />
            </div> 
        }
        </UserContext.Provider>
      </div>
    )
  }
}
