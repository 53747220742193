import React from "react"
import { Filters, TimeOfDay } from "./Filter"
import { Category } from "./category"
import { timeNumberToString } from "./datetime"
import "./FilterHeader.css"
import spacetime, { Spacetime } from "spacetime"

interface FilterHeaderProps {
  filter: Filters
  onClearFilter: () => void
}

const categorySpan = (category: Category | null) => {
  if (category === null) return null
  switch (category) {
    case Category.ASLEEP:
      return <span className="filter-indicator category-1">Asleep</span>
    case Category.AWAKE:
      return <span className="filter-indicator category-2">Awake</span>
    case Category.PUT_DOWN:
      return <span className="filter-indicator category-3">Put Down</span>
    case Category.UNKNOWN:
      return <span className="filter-indicator category-0">Notes</span>
    default:
      return null
  }
}

const hourRangeSpan = (timeOfDay: TimeOfDay, starts: number | null, ends: number | null) => {
  switch (timeOfDay) {
    case TimeOfDay.DayTime:
      return <span className="filter-indicator">Daytime</span>
    case TimeOfDay.NightTime:
      return <span className="filter-indicator">Nighttime</span>
    case TimeOfDay.Custom:
      return <span className="filter-indicator">{timeNumberToString(starts!)} - {timeNumberToString(ends!)}</span>
    default:
      return <></>
  }
}

const dateRangeSpan = (dateFrom: Spacetime | null, dateTo: Spacetime | null) => {
  const formatDate = (dt: Spacetime): string => {
    const now = spacetime.now(dt.timezone().name)
    if (now.year() === dt.year()) {
      return dt.format("{day-short} {month-short} {date}") as string
    } else {
      return dt.format("{day-short} {month-short} {date}, {year}") as string
    }
  }
  if (dateFrom === null) return (<></>)
  if (dateTo === null) {
    return <span className="filter-indicator">{formatDate(dateFrom)}</span>
  } else {
    return <span className="filter-indicator">{formatDate(dateFrom)} - {formatDate(dateTo)}</span>
  }
}

export default function FilterHeader(props: FilterHeaderProps) {
  return (
    <div className="display filter-header">
      <div>
        {categorySpan(props.filter.category)}
        {hourRangeSpan(props.filter.timeOfDay, props.filter.timeOfDayStarts, props.filter.timeOfDayEnds)}
        {dateRangeSpan(props.filter.dateFrom, props.filter.dateTo)}
        <button className="clear" onClick={() => props.onClearFilter()}>Clear</button>
      </div>
    </div>
  )
}