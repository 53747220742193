import React from "react"
import { formatDuration } from "./datetime"
import "./DateHeader.css"

interface DateHeaderProps {
  date: string
  totalSleep: number
}

export default function DateHeader(props: DateHeaderProps) {
  return (
    <div className="display">
      <div className="date">{props.date}</div>
      <div className={`sleep-duration${props.totalSleep === 0 ? " off" : ""}`}>
        <span className="caption">Total Sleep</span>
        <span className="duration">{formatDuration(props.totalSleep)}</span>
      </div>
    </div>
  )
}