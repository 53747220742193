import React from "react"
import { PhoneNumberType } from "./phone_numbers"

interface HelpProps {
  className: string
  phoneNumber: PhoneNumberType
}

export default function Help(props: HelpProps) {
  return (<div className={props.className}>
    <h2 className="first">The Basics</h2>
    <ul className="basics">
      <li>
        <h3>Log Now</h3>
        <p>Log events as they happen. Tiny Log uses the timestamp from your text message. <span role="img" aria-label="hint">💡</span>Add notes, like where a nap happened, to your message.</p>
        <div className="box">
          <div className="balloon"><span>Asleep in stroller</span></div>
          <div className="balloon"><span>Put down</span></div>
          <div className="balloon"><span>Awake</span></div>
        </div>
      </li>
      <li>
        <h3>Log Later</h3>
        <p>Include a time in your message to log something after the fact. Separate multiple entries by line.</p>
        <div className="box">
          <div className="balloon"><span>Woke up at 8</span></div>
          <div className="balloon"><span>Lay down 10:13<br />Sleep 10:24<br />Wake 11:20</span></div>
          <div className="balloon"><span>Up 115</span></div>
        </div>
      </li>
      <li>
        <h3 id="log-together">Log Together</h3>
        <p>Allow others to contribute to your log. Text "Share with" and their phone number. <span role="img" aria-label="hint">💡</span>Add your partner to a text thread with Tiny Log so you can both keep up.</p>
        <div className="box">
          <div className="balloon"><span>Share with 821234567890</span></div>
        </div>
      </li>
      <li>
        <h3>Keep Track</h3>
        <p>Get the latest status as a text message or the link to view and edit your entire log.</p>
        <div className="box">
          <div className="balloon"><span>Status</span></div>
          <div className="balloon"><span>Get log</span></div>
        </div>
      </li>
      <li>
        <h3>Keep Notes</h3>
        <p>Log more than just sleep. Send text messages about feedings, diapers, milestones and more. Just be sure to avoid keywords like "awake".</p>
        <div className="box">
          <div className="balloon"><span>Ate banana <span role="img" aria-label="banana">🍌</span></span></div>
          <div className="balloon"><span>Touched his toes</span></div>
        </div>
      </li>
      <li>
        <h3>Fix a Mistake</h3>
        <p>Delete your last entry with a text. Edit or delete any entry by texting "Get log" and following your unique link.</p>
        <div className="box">
          <div className="balloon"><span>Undo</span></div>
          <div className="balloon"><span>nm</span></div>
          <div className="balloon"><span>Oops</span></div>
        </div>
      </li>
    </ul>
    <h2>Pro Tips</h2>
    <ul className="pro-tips">
      <li>
        <h3>Save Tiny Log as a Contact</h3>
        <p>It'll make logging so much easier. <a href="https://tinylog.iandco.com/tinylog.vcf">Download Tiny Log's contact card</a>.</p>
      </li>
      <li>
        <h3>Log with Your Voice</h3>
        <p>Now that Tiny Log is saved as a contact in your phone, you can ask your voice assistant to send a log entry for you.</p>
        <p>For example: "Siri, text Tiny Log Asleep" or "Alexa, send a text message"</p>
      </li>
      <li>
        <h3>Add a Home Screen Link</h3>
        <p>Add an icon to your phone's home screen for one-tap access to your log.</p>
        <p>In Safari: Go to your log <span role="img" aria-label="arrow-right">➡️</span> Tap the share button <span role="img" aria-label="arrow-right">➡️</span> Tap Add to Home Screen</p>
        <p>In Android Chrome: Go to your log <span role="img" aria-label="arrow-right">➡️</span> Tap the menu button <span role="img" aria-label="arrow-right">➡️</span> Tap add to homescreen</p>
      </li>
      <li>
        <h3>Share Tiny Log</h3>
        <p>Have a friend who might like to use Tiny Log? They can send a text to <a href={props.phoneNumber.link}>{props.phoneNumber.display}</a> and follow the instructions to create their own log.</p>
        <p>Want them to be able to edit your log? In that case, see the "<a href="#log-together">Log Together</a>" section above.</p>
      </li>
    </ul>
  </div>)
}