import spacetime from "spacetime"

/**
 * Formats a timestamp and returns a string such as "7:07 PM Yesterday".
 */
export function formatDateTime(timestamp: number, timezone: string): string {
  const datetime = spacetime(timestamp, timezone)
  const now = spacetime.now(timezone)

  const time = (datetime.format("{hour}:{minute-pad} {ampm}") as string).toUpperCase()

  let date
  if (datetime.format("{year}{month-pad}{date-pad}") === now.format("{year}{month-pad}{date-pad}")) {
    date = ""
  } else if (datetime.format("{year}{month-pad}{date-pad}") === now.subtract(24, "hour").format("{year}{month-pad}{date-pad}")) {
    date = "• Yesterday"
  } else {
    date = datetime.format("• {day-short}, {month-short} {date}")
  }

  return `${time} ${date}`
}

/**
 * Formats a timestamp and returns a string such as "Mon Nov 22, 2019"
 *
 * Note that a day starts at `dayStarts` hour, not midnight.
 * So even if the day was already Nov 22nd for example, if the dayStarts was 700
 * and the tiemstamp was 5am Nov 22nd, it returns "Nov 21, 2019".
 */
export function formatDateShort(timestamp: number, timezone: string, dayStarts: number): string {
  let datetime = spacetime(timestamp, timezone)
  if (datetime.hour() * 100 + datetime.minute() < dayStarts) {
    datetime = datetime.subtract(24, "hour")
  }
  const now = spacetime.now(timezone)
  if (now.year() === datetime.year()) {
    return datetime.format("{day-short} {month-short} {date}") as string
  } else {
    return datetime.format("{day-short} {month-short} {date}, {year}") as string
  }
}

/**
 * Formats a timestamp and returns a string such as "Monday November 22, 2019"
 *
 * Note that a day starts at `dayStarts` hour, not midnight.
 * So even if the day was already Nov 22nd for example, if the dayStarts was 700
 * and the tiemstamp was 5am Nov 22nd, it returns "Sunday November 21, 2019".
 */
export function formatDateLong(timestamp: number, timezone: string, dayStarts: number): string {
  let datetime = spacetime(timestamp, timezone)
  if (datetime.hour() * 100 + datetime.minute() < dayStarts) {
    datetime = datetime.subtract(24, "hour")
  }
  const now = spacetime.now(timezone)
  if (now.year() === datetime.year()) {
    return datetime.format("{day}, {month} {date}") as string
  } else {
    return datetime.format("{day}, {month} {date}, {year}") as string
  }
}

/**
 * Formats a timestamp and returns a string such as "2019-11-02" for <input type="date">
 */
export function formatDate(timestamp: number, timezone: string): string {
  const datetime = spacetime(timestamp, timezone)
  return datetime.format("{year}-{iso-month}-{date-pad}") as string
}

/**
 * Formats a timestamp and returns a string such as "09:24" for <input type="time">
 */
export function formatTime(timestamp: number, timezone: string): string {
  const datetime = spacetime(timestamp, timezone)
  return datetime.format("{hour-24-pad}:{minute-pad}") as string
}

/**
 * Formats a duration (in milliseconds) to a string such as "4 hours 21 minutes".
 *
 * TODO: limit should be a parameter. So is "15+ hours" string.
 */
export function formatDuration(duration: number): string {
  const LIMIT = 15 * 60 * 60 * 1000 // 15 hours
  if (duration > LIMIT) {
    return "15+ hours"
  }
  const _minutes = Math.floor(duration / (60 * 1000))
  const hours = Math.floor(_minutes / 60)
  const minutes = _minutes - (hours * 60)
  if (hours < 1) {
    if (minutes > 1) {
      return minutes + " minutes"
    } else {
      return "1 minute"
    }
  } else {
    const ret = []
    if (hours > 1) {
      ret.push(hours + " hours")
    } else {
      ret.push("1 hour")
    }
    if (minutes > 1) {
      ret.push(minutes + " minutes")
    } else if (minutes > 0) {
      ret.push("1 minute")
    }
    return ret.join(" ")
  }
}

export function parseTimeNumber(value: number): [number, number] {
  if (value < 0 || value >= 2400) throw new Error("Invalid hour value: " + value)

  const hour = value / 100 < 1 ? 0 : Math.floor(value / 100)
  const minute = value - hour * 100 > 59 ? 0 : value - hour * 100
  if (minute > 59) throw new Error("Invalid hour value: " + value)

  return [hour, minute]
}

export function timeNumberToString(value: number): string {
  const [hour, minute] = parseTimeNumber(value)
  const m = minute > 9 ? `${minute}` : `0${minute}`
  if (hour === 0) {
    return `12:${m} am`
  } else if (hour < 12) {
    return `${hour}:${m} am`
  } else if (hour === 12) {
    return `${hour}:${m} pm`
  } else {
    return `${hour - 12}:${m} pm`
  }
}

export function timeNumberToTimeValue(value: number): string {
  const [hour, minute] = parseTimeNumber(value)
  return `${hour > 10 ? hour : "0" + hour}:${minute > 10 ? minute : "0" + minute}`
}

const TIME_REGEXP = /(\d{3,4}|\d{1,2}:\d{2}|\d{1,2})\s*(am|pm|a$|a\s|p$|p\s)?/i
/**
 * Parses raw string and returns time in number (0 - 2359).
 */
export function parseTimeDisplay(raw: string): number {
  const match = TIME_REGEXP.exec(raw)
  if (match === null || match.length < 2) return 0

  const time = parseInt(match[1].replace(":", ""))
  if (time > 2359) return 0

  const ampm = (typeof match[2] === "undefined")
    ? null
    : match[2].startsWith("a")
      ? "am"
      : match[2].startsWith("p")
        ? "pm"
        : null

  let h: number
  let m: number
  if (time / 100 < 1) {
    // time consists only of hour like "1pm"
    h = time
    m = 0
  } else {
    h = Math.floor(time / 100)
    m = time - (h * 100)
  }

  if (h > 23) {
    if (match[1].startsWith("00") || match[1].startsWith("0")) {
      // 050 or 0050 is 50 minutes past midnight, for example
      m = h
      h = 0
    } else {
      // invalid time
      return 0
    }
  }

  if (h > 12) {
    return h * 100 + m
  } else if (h === 12) {
    if (ampm === "am") {
      return m
    } else {
      return 1200 + m
    }
  } else {
    if (ampm === "pm") {
      return h * 100 + m + 1200
    } else {
      return h * 100 + m
    }
  }
}

export function addHours(value: number, hours: number): number {
  let [hour, minute] = parseTimeNumber(value)
  hour += hours
  if (hour >= 24) hour -= 24
  if (hours === 24) {
    if (hour === 0) {
      hour = hours - 1
    } else {
      hour = hour - 1
    }
    if (minute === 0) {
      minute = 59
    } else {
      minute = minute - 1
    }
  }
  return hour * 100 + minute
}

export function startOfDayFor(timestamp: number, timezone: string, dayStarts: number): number {
  const dt = spacetime(timestamp, timezone)
  const [hour, minute] = parseTimeNumber(dayStarts)
  const start = spacetime([dt.year(), dt.month(), dt.date(), hour, minute, 0], timezone)
  return start.epoch <= timestamp ? start.epoch : start.epoch - 24 * 60 * 60 * 1000
}

// TODO these should come from database
export const TIMEZONES = {
  "US": [
    { value: "America/New_York", label: "Eastern Time" },
    { value: "America/Chicago", label: "Central Time" },
    { value: "America/Phoenix", label: "Mountain Time" },
    { value: "America/Los_Angeles", label: "Pacific Time" },
  ],
  "CA": [
    { value: "America/Toronto", label: "Eastern Time (ET)" },
    { value: "America/Winnipeg", label: "Central Time (CT)" },
    { value: "America/Edmonton", label: "Mountain Time (MT)" },
    { value: "America/Vancouver", label: "Pacific Time (PT)" },
    { value: "America/Halifax", label: "Atlantic Time (AT)" },
    { value: "America/St_Johns", label: "Newfoundland Time (NT)" },
  ],
}