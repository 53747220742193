import React from "react"
import { Category } from "./category"
import { CalendarEntry } from "./Calendar"
import { IEntry } from "./Entry"

interface DayProps {
  entry: CalendarEntry
  index: number
  now: number
  selectedEntry: IEntry | null
  onSelected: (calendarIndex: number, entryIndex: number, entry: IEntry) => void
}

const _color = (canvas: HTMLCanvasElement, category: Category): string => {
  // TODO when you update a color here you need to update `index.css` too.
  switch (category) {
    case Category.UNKNOWN: return window.getComputedStyle(canvas).getPropertyValue("--tinylog-note-color")
    case Category.ASLEEP: return window.getComputedStyle(canvas).getPropertyValue("--tinylog-asleep-color")
    case Category.AWAKE: return window.getComputedStyle(canvas).getPropertyValue("--tinylog-awake-color")
    case Category.PUT_DOWN: return window.getComputedStyle(canvas).getPropertyValue("--tinylog-putdown-color")
    default: return window.getComputedStyle(canvas).getPropertyValue("--tinylog-white")
  }
}

const MINIMUM_SLOT_WIDTH = 8 //px

const BASE = 24 * 60
const _timeToPosition = (epoch: number, zero: number): number => {
  const value = Math.floor((epoch - zero) / 600)
  return Math.floor(value / BASE) / 100
}

const _positionToTime = (x: number, zero: number): number => {
  const value = x * 100 * BASE
  return value * 600 + zero
}

const _drawOngoingMarker = (end: number, width: number, canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D) => {
  ctx.save()
  ctx.beginPath()
  ctx.rect(end - width, 0, width, canvas.height)
  ctx.clip()
  ctx.translate(end - 2, -canvas.height / 3.6)
  ctx.rotate(0.6)
  ctx.fillStyle = window.getComputedStyle(canvas).getPropertyValue("--tinylog-background-color")
  for (let i = 1; i < 8; i++) {
    ctx.fillRect(8 * i, 0, 4, canvas.height * 2)
  }
  ctx.restore()
}

const _drawEntries = (props: DayProps, canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D) => {
  let startDiff = 0
  props.entry.entries.filter(e => e.category !== Category.UNKNOWN).forEach(bar => {
    ctx.save()
    ctx.fillStyle = _color(canvas, bar.category)
    const _start = _timeToPosition(bar.timestamp, props.entry.since)
    const start = _start < 0 ? 0 : _start
    const _end = _timeToPosition(
      bar.duration === 0 ? props.now : bar.timestamp + bar.duration,
      props.entry.since
    )
    const end = _end > 0.98 ? 1.0 : _end
    const left = start * canvas.width + startDiff
    const width = Math.max((end - start) * canvas.width, MINIMUM_SLOT_WIDTH)
    ctx.fillRect(left, 0, width, canvas.height)
    // If end === 1.0 it most likely means continuation to the next day,
    // so don't draw the ongoing marker in that case
    // There is a small possibility that you are looking at an ongoing entry
    // at the end of "your day", in which case the ongoing marker is not drawn
    // when it should be.
    if (bar.duration === 0 && end < 1.0) _drawOngoingMarker(left + width, width, canvas, ctx)

    // If this entry was selected, draw a border around it
    if (props.selectedEntry !== null && props.selectedEntry.id === bar.id) {
      ctx.beginPath()
      ctx.lineWidth = 3
      ctx.strokeStyle = window.getComputedStyle(canvas).getPropertyValue("--tinylog-red")
      ctx.rect(left + 1.5, 1.5, width - 1.5, canvas.height - 3)
      ctx.stroke()
    }
    ctx.restore()
    startDiff = width - ((end - start) * canvas.width)
  })
}

const _drawNotes = (props: DayProps, canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D) => {
  let lastNoteStartAt = 0
  let lastNoteEnd = 0
  let overdrawn = 0
  const noteWidth = canvas.height / 2
  props.entry.entries.filter(e => e.category === Category.UNKNOWN).forEach(bar => {
    const strokeStyle = props.selectedEntry !== null && props.selectedEntry.id === bar.id
      ? window.getComputedStyle(canvas).getPropertyValue("--tinylog-red")
      : window.getComputedStyle(canvas).getPropertyValue("--tinylog-white")
    const start = _timeToPosition(bar.timestamp, props.entry.since)
    if (start * canvas.width < lastNoteEnd) {
      __drawNoteBack(canvas, ctx, strokeStyle, bar, props.entry.since, lastNoteStartAt, overdrawn + 1)
      overdrawn = overdrawn + 1
    } else {
      __drawNoteFront(canvas, ctx, strokeStyle, bar, props.entry.since)
      overdrawn = 0
      lastNoteStartAt = bar.timestamp
    }
    lastNoteEnd = start * canvas.width + noteWidth
  })
}

const __drawNoteFront = (canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, strokeStyle: string, bar: IEntry, zero: number) => {
  ctx.save()
  ctx.lineWidth = 2.5
  ctx.strokeStyle = strokeStyle
  const start = _timeToPosition(bar.timestamp, zero)
  const height = canvas.height / 3
  const width = height
  ctx.beginPath()
  ctx.fillStyle = window.getComputedStyle(canvas).getPropertyValue("--tinylog-background-color")
  ctx.translate(start * canvas.width, height)
  ctx.moveTo(0, 0)
  ctx.lineTo(4 * width / 7, 0)
  ctx.lineTo(width, canvas.height / 6)
  ctx.lineTo(width, height)
  ctx.lineTo(0, height)
  ctx.lineTo(0, 0)
  ctx.stroke()
  ctx.fill()
  ctx.beginPath()
  ctx.fillStyle = _color(canvas, bar.category)
  ctx.moveTo(width, canvas.height / 6)
  ctx.lineTo(4 * width / 7, canvas.height / 6)
  ctx.lineTo(4 * width / 7, 0)
  ctx.stroke()
  ctx.fill()
  ctx.restore()
}

const __drawNoteBack = (canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, strokeStyle: string, bar: IEntry, zero: number, lastNoteStartAt: number, numOverDrawn: number) => {
  ctx.lineWidth = 1
  ctx.strokeStyle = strokeStyle
  const start = _timeToPosition(lastNoteStartAt, zero)
  const height = canvas.height / 3
  ctx.save()
  ctx.translate(start * canvas.width - numOverDrawn * 3, height + numOverDrawn * 3)
  ctx.beginPath()
  ctx.strokeStyle = window.getComputedStyle(canvas).getPropertyValue("--tinylog-background-color")
  ctx.moveTo(0, 1)
  ctx.lineTo(0, height)
  ctx.lineTo(height - 1, height)
  ctx.stroke()
  ctx.beginPath()
  ctx.strokeStyle = strokeStyle
  ctx.moveTo(1, 0)
  ctx.lineTo(-1, 0)
  ctx.lineTo(-1, height + 1)
  ctx.lineTo(height, height + 1)
  ctx.lineTo(height, height - 1)
  ctx.stroke()
  ctx.restore()
}

const _onCanvasClick = (e: React.MouseEvent, props: DayProps) => {
  const canvas = e.target as HTMLCanvasElement
  const entriesDiv = window.document.querySelector(".entries")
  if (entriesDiv === null) return

  // Determine "when" in a bar (canvas) the click was registered
  const canvasLeft = canvas.offsetLeft
  const scrollLeft = entriesDiv.scrollLeft
  const timestamp = _positionToTime((e.clientX - canvasLeft + scrollLeft) / canvas.offsetWidth, props.entry.since)

  // Collect all entries that were happening during the "when" (i.e. the position of the click)
  const entries = []
  for (let i = 0; i < props.entry.entries.length; i++) {
    const e = props.entry.entries[i]
    // Duration could be ...
    const until = e.duration > 0
      ? e.timestamp + e.duration // ... a) duration of an entry; or
      : e.category === Category.UNKNOWN
        ? e.timestamp + ((canvas.height / 3) / canvas.offsetWidth) * 24 * 60 * 60 * 1000 // ... b) Width of a note if the entry was a note because a note doesn't have a duration; or
        : props.now // ... c) until now

    // If the entry was happening in the duration, collect it
    // TODO this is not considering the `y` position of the click so it could find a note when the click was
    // at the top or at the bottom of the bar.
    if (e.timestamp <= timestamp && timestamp < until) {
      entries.push({ index: i, entry: e })
    }
  }

  if (entries.length === 1) {
    // If only one entry was found, notify the parent about it
    props.onSelected(props.index, entries[0].index, entries[0].entry)
  } else if (entries.length > 1) {
    // If more than an entry were found, ...
    for (let j = entries.length - 1; j >= 0; j--) {
      // ... first, look for a nore because a note is rendered on top of other entries
      if (entries[j].entry.category === Category.UNKNOWN) {
        // If a note was found, notify the parent about it
        props.onSelected(props.index, entries[j].index, entries[j].entry)
        return
      }
    }
    // ... if no notes were found, notify the parent about the first entry at the position.
    props.onSelected(props.index, entries[0].index, entries[0].entry)
  }
}

const _formatDuration = (duration: number) => {
  const _minutes = Math.floor(duration / (60 * 1000))
  const hours = Math.floor(_minutes / 60)
  const minutes = _minutes - (hours * 60)
  if (hours < 1) {
    return minutes + " min"
  } else {
    const ret = []
    ret.push(hours + " h")
    if (minutes > 1) {
      ret.push(minutes + " min")
    }
    return ret.join(" ")
  }
}

const _summarizeTotalSleep = (props: DayProps) => {
  const sumDuration = props.entry.entries
    .filter(e => e.category === Category.ASLEEP)
    .reduce((v, e) => {
      const since = e.timestamp > props.entry.since
        ? e.timestamp
        : props.entry.since
      const until = e.timestamp + e.duration > props.entry.until
        ? props.entry.until
        : e.timestamp + e.duration
      return v + (until - since)
    }, 0)
  return _formatDuration(sumDuration)
}

const onGotCanvas = (props: DayProps, canvas: HTMLCanvasElement | null) => {
  if (canvas === null) return
  const ctx = canvas.getContext("2d")
  if (ctx === null) return
  if (canvas.getAttribute("data-drawn") !== null && props.selectedEntry === null) return
  canvas.width = canvas.offsetWidth
  canvas.height = canvas.offsetHeight
  _drawEntries(props, canvas, ctx)
  _drawNotes(props, canvas, ctx)
  canvas.setAttribute("data-drawn", "1")
}

export default function Day(props: DayProps) {
  return (
    <li className="day">
      <div className="tape">
        <canvas ref={(e) => onGotCanvas(props, e)} onClick={e => _onCanvasClick(e, props)}></canvas>
      </div>
      <div className="summary">
        {_summarizeTotalSleep(props)}
        { props.entry.until > props.now ? <span>so far</span> : null }
      </div>
    </li>
  )
}
