import React, { useState, useContext } from "react"
import Header from "./Header"
import Entries from "./Entries"
import Calendar from "./Calendar"
import { Filters } from "./Filter"
import "./Home.css"
import { pageevent } from "./ga"
import { UserContext } from "./user"
import spacetime from "spacetime"
import { ViewMode } from "./Navbar"

interface HomeProps {
  filter: Filters
  isPWA: boolean
  viewMode: ViewMode
  onFilter: () => void
  onClearFilter: () => void
  onGoToDay: (timestamp: number) => void
  onGoToTips: () => void
}

interface HomeState {
  showBanner: boolean
  currentDate: string
  currentTotalSleep: number
}

const LOCALSTORAGE_KEY_SHOW_BANNER = "tinylogHomeShowBanner"

export default function Home(props: HomeProps) {
  const userContext = useContext(UserContext)
  const showBannerValue = sessionStorage.getItem(LOCALSTORAGE_KEY_SHOW_BANNER)
  let showBanner: boolean = false
  if (showBannerValue === null) {
    if (props.isPWA) {
      sessionStorage.setItem(LOCALSTORAGE_KEY_SHOW_BANNER, "false")
    } else {
      sessionStorage.setItem(LOCALSTORAGE_KEY_SHOW_BANNER, "true")
      showBanner = true
    }
  } else if (showBannerValue === "true") {
    showBanner = true
  }
  const [state, setState] = useState<HomeState>({
    currentDate: "",
    currentTotalSleep: 0,
    showBanner: showBanner
  })
  const close = () => {
    pageevent("pwabanner", "close")
    sessionStorage.setItem(LOCALSTORAGE_KEY_SHOW_BANNER, "false")
    setState({ ...state, showBanner: false })
  }
  return (<>
    <Header
      filterable={props.viewMode !== ViewMode.Timeline}
      date={state.currentDate}
      totalSleep={state.currentTotalSleep}
      filter={props.filter}
      onFilter={props.onFilter}
      onClearFilter={() => {
        setState({ ...state, currentDate: "", currentTotalSleep: 0 })
        props.onClearFilter()
      }}
    />
    { props.viewMode === ViewMode.Timeline
      ? <Calendar
          now={spacetime.now(userContext.user.timezone).epoch}
          onDateChanged={date => setState({ ...state, currentDate: date })}
          goToTips={() => props.onGoToTips()}
        />
      : <Entries
        filter={props.filter}
        onDateChanged={
          (date, totalSleep) => setState({ ...state, currentDate: date, currentTotalSleep: totalSleep })
        }
        goToDay={(timestamp) => props.onGoToDay(timestamp)}
        goToTips={() => props.onGoToTips()}
      />
    }
    {
      state.showBanner
        ? <div className="banner">
          <p><span role="img" aria-label="hint">💡</span>Tap below and select "Add to Home Screen" to make it easier to access your log.</p>
          <div className="pointer"></div>
          <button className="close" onClick={close}>
            <img src="/cross.svg" alt="Close" />
          </button>
        </div>
        : null
    }
  </>)
}